<template>
  <div class="mt-4 h-full w-full space-y-4">
    <BaseCard class="h-full md:h-24" v-if="myAddress?.length == 0">
      <div
        class="lg:text-large h-full w-full items-center space-x-3 space-y-4 px-3 text-xs text-neutral-200 md:flex md:space-y-0"
      >
        <div class="flex space-x-3 px-3 lg:w-1/3">
          <img src="../../images/warning.svg" class="flex-shrink-0" />
          <div class="text-xs">
            The wallet you have added does not have any activity or is a smart
            contract. Add a personal wallet with activity to see the Chill
            Factor score.
          </div>
        </div>
        <button
          @click="openModal()"
          class="rounded border border-[#8B9FEA] py-2 px-4 text-[#8B9FEA] backdrop-blur-lg"
        >
          CONNECT NEW WALLET
        </button>
      </div>
    </BaseCard>

    <div class="w-full space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
      <div
        id="chill-factor-score-card"
        class="relative w-full rounded-2xl lg:w-2/3"
        :class="currentSnapShot?.current_snapshot?.score ? '!bg-primary' : ''"
      >
        <BaseCard
          :class="[
            currentSnapShot?.current_snapshot?.score
              ? `${setBgScoreBaseColor(
                  currentSnapShot?.current_snapshot?.score,
                )}`
              : 'default_mcf_bg',
            `${currentSnapShot?.current_snapshot?.tier?.toLowerCase()}-border`,
          ]"
        >
          <div class="pb-2 pt-4 font-normal">
            <div class="font-semibold">
              <div class="flex items-center justify-between">
                <div class="flex items-center text-xl text-[#F3F2F6]">
                  Chill Factor
                  <Tooltip
                    :text="chillFactorTooltipText"
                    class="ml-1"
                    v-if="false"
                  >
                    <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
                  </Tooltip>
                  <IconLoading class="w-1/12" v-if="loading" />
                </div>
                <div class="flex items-center justify-center" v-if="!loading">
                  <div
                    :class="[
                      !imageLoading ? 'flex' : 'flex',
                      'included items-center space-x-2 divide-x divide-[#F3F2F629] transition-all duration-300 ease-out',
                    ]"
                  >
                    <div>
                      <img
                        class="h-2.5"
                        src="../../images/frosty_logo.svg"
                        alt="Frosty Metrics Logo"
                      />
                    </div>
                    <div class="px-2">
                      <img
                        class="h-2.5"
                        src="../../images/the_tie_logo.svg"
                        alt="The Tie Logo"
                      />
                    </div>
                  </div>
                  <div
                    v-if="currentSnapShot?.current_snapshot"
                    class="exclude flex-shrink-0 hover:bg-white/10"
                    :class="[
                      imageLoading
                        ? 'pointer-events-none p-2'
                        : 'cursor-pointer',
                      'rounded',
                      ,
                      `${currentSnapShot?.current_snapshot?.tier?.toLowerCase()}-border`,
                    ]"
                    @click.stop="shareOnTwitter"
                  >
                    <IconLoading
                      v-if="imageLoading"
                      class="h-4 w-4 text-gray-100"
                    />
                    <Button class="px-1 text-xs text-whiteSmokeAlpha-88" v-else
                      >X (Twitter)</Button
                    >
                    <!-- <ShareIcon
                      v-else
                      class="h-4 w-4 cursor-pointer text-gray-100"
                    /> -->
                  </div>
                </div>
              </div>
              <div
                class="text-xs font-light text-neutral-400"
                v-if="currentSnapShot?.most_recent_metrics?.last_updated"
              >
                UPDATED:
                {{
                  new Date(
                    currentSnapShot?.most_recent_metrics.last_updated,
                  ).toLocaleDateString()
                }}
              </div>
              <div v-else>
                <span class="text-xs font-light text-neutral-400">
                  <span>UPDATED:</span> --
                </span>
              </div>
            </div>
          </div>
          <div
            class="h-full w-full justify-between space-y-4 pb-4 lg:flex lg:items-end lg:space-y-0 lg:space-x-3"
          >
            <div class="w-full lg:w-1/3">
              <div class="pb-4 text-4xl font-bold">
                <span
                  v-if="currentSnapShot?.current_snapshot?.score"
                  :class="`${currentSnapShot?.current_snapshot?.tier?.toLowerCase()}-text-gradient`"
                >
                  {{
                    simpleNumberFormatter(
                      currentSnapShot?.current_snapshot.score,
                    )
                  }}
                </span>
                <span v-else-if="loading">
                  <span class="default_mcf_text">0</span>
                </span>
                <span v-else-if="!loading" class="flex items-center space-x-2">
                  <span class="default_mcf_text text-4xl">0</span>
                  <span class="flex text-sm font-light text-red-500">
                    <img src="../../images/warning.svg" class="px-2" /> NO
                    WALLET ADDRESS CONNECTED</span
                  >
                </span>
              </div>
              <div
                class="border-1 box-shadow relative flex h-auto w-full flex-col overflow-hidden rounded-lg border border-gray-500/30 border-opacity-60 bg-gray-600 bg-transparent bg-opacity-10 text-base font-normal backdrop-blur-3xl"
              >
                <div class="flex flex-col text-purple-100/80">
                  <div class="overflow-hidden">
                    <ChillFactorStats
                      :my-address-status="currentSnapShot?.current_snapshot"
                      :current-snapshot="currentSnapShot"
                      :loading="loading"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Tier Progress Bar & Scaling -->
            <TierProgressBar
              :current-snap-shot="currentSnapShot"
              :checkpoint-stats-historical="checkpointStatsHistorical"
              class="w-full text-xs lg:w-2/3 xl:h-full"
            />
          </div>
          <div
            class="absolute mt-14 h-[20rem] w-full opacity-40 md:h-full md:w-6/12"
            :class="`${
              currentSnapShot?.current_snapshot?.score ? '!w-full' : ''
            }`"
          >
            <template
              v-if="
                currentSnapShot?.current_snapshot?.tier?.toLowerCase() ==
                  'warm' && !loading
              "
            >
              <div v-for="index in 2" :key="index">
                <RainAnimation />
              </div>
            </template>

            <template
              v-else-if="
                currentSnapShot?.current_snapshot?.tier?.toLowerCase() !=
                  'warm' && !loading
              "
            >
              <div v-for="index in 2" :key="index">
                <SnowAnimation />
              </div>
            </template>
          </div>
        </BaseCard>
      </div>
      <Leaderboard class="w-full lg:w-1/3" :loading="loading" />
    </div>

    <!-- stats with Line graph section -->
    <section>
      <MiniGraphs
        :loading="loading"
        :component-historical="checkpointStatsHistorical?.components_historical"
        class="h-full w-full"
      />
    </section>

    <!-- stats with Line graph section -->
    <section>
      <Checkpoints
        :checkpoint-snapshot="checkpointSnapShot"
        :loading="loading"
      />
    </section>

    <ConnectWalletModal
      :show-modal="showModal"
      v-if="showModal"
      @closeModal="closeModal()"
      @entered-Address="(x) => enteredAddress(x)"
      :tier="currentSnapShot?.current_snapshot?.tier"
    />

    <ShowLevelModal
      :show-modal="showLevelModal && currentSnapShot?.current_snapshot"
      :chill-factor="currentSnapShot?.current_snapshot"
      v-if="showLevelModal"
      @closeModal="closeLevelModal()"
    />

    <ShareScoreModal
      v-if="openShareModal"
      :is-open="openShareModal"
      :data-url="imageDataUrl"
      :max-width="true"
      :tier="currentSnapShot?.current_snapshot?.tier"
      :score="currentSnapShot?.current_snapshot?.score"
      @close="closeShareModal"
    />
  </div>
  <!-- Hidden for Sharing -->
  <div
    style="width: 700px; height: 350px"
    class="absolute top-0 left-0 z-[-9999] space-y-4 lg:flex lg:space-y-0 lg:space-x-4"
  >
    <div
      style="width: 700px; height: 350px"
      id="chill-factor-score-card-2"
      class="relative rounded-2xl"
      :class="currentSnapShot?.current_snapshot?.score ? '!bg-primary' : ''"
    >
      <BaseCard
        style="width: 700px; height: 350px"
        :class="[
          checkpointStatsHistorical?.current_snapshot?.score
            ? `${setBgScoreBaseColor(
                checkpointStatsHistorical?.current_snapshot?.score,
              )}`
            : 'default_mcf_bg',
          `${checkpointStatsHistorical?.current_snapshot?.tier?.toLowerCase()}-border`,
        ]"
      >
        <div class="h-full w-full">
          <div
            v-if="selectedConfig.id != 'warm'"
            class="exclude absolute -left-2.5 -right-2.5 z-20 block"
            :class="[
              snowClass?.[selectedConfig.id],
              ['chilly', 'lukewarm'].includes(selectedConfig?.id)
                ? '-top-2.5'
                : '-top-5',
            ]"
          >
            <img :src="selectedConfig.imageUrl" class="w-full flex-shrink-0" />
          </div>
          <div
            class="flex w-full flex-col items-center justify-center space-y-4 pt-8"
          >
            <div class="text-lg font-black tracking-widest">
              {{ selectedConfig?.header }}
            </div>
            <div>
              <img :src="selectedConfig.textUrl" class="flex-shrink-0" />
            </div>
            <div
              class="text[#f3f2f6b8] text-xs font-medium leading-4 tracking-wide"
            >
              Chill Factor Range
            </div>
          </div>
          <div class="flex items-center justify-center pt-2">
            <button
              class="flex h-7 cursor-default items-center space-x-1 rounded px-4 text-sm"
              :style="selectedConfig?.rangeStyle"
              :class="selectedConfig?.rangeClass"
            >
              <CrownIcon />
              <span>{{ selectedConfig?.range }}</span>
            </button>
          </div>

          <div
            :class="`${setTier(
              checkpointStatsHistorical?.current_snapshot?.score,
            )?.toLowerCase()}-text-gradient py-6 text-center text-4xl font-black`"
          >
            {{
              simpleNumberFormatter(
                checkpointStatsHistorical?.current_snapshot?.score,
              )
            }}
          </div>
          <div
            :class="[
              !loadingImage ? 'flex' : 'flex',
              'included items-center justify-center space-x-3 divide-x',
            ]"
          >
            <div>
              <img
                class="h-2.5"
                src="../../images/frosty_logo.svg"
                alt="Frosty Metrics Logo"
              />
            </div>
            <div class="px-2">
              <img
                class="h-3"
                src="../../images/the_tie_logo.svg"
                alt="The Tie Logo"
              />
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import BaseCard from '../../Shared/BaseCard.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
import {
  findMyStats,
  setScoreBaseTextColor,
  simpleNumberFormatter,
  setBgScoreBaseColor,
  setMinMaxTierCutOff,
  setTier,
  snowClass,
  chillFactorConfig,
} from '../../composeables/filters';
import Checkpoints from './Checkpoints.vue';
import Leaderboard from './Leaderboard.vue';
import ChillFactorStats from './ChillFactorStats.vue';
import IconLoading from '../../icons/IconLoading.vue';
import ShareIcon from '../../icons/ShareIcon.vue';
import MiniGraphs from './MiniGraphs.vue';
import ConnectWalletModal from '../../Shared/ConnectWalletModal.vue';
import ShowLevelModal from '../../Shared/ShowLevelModal.vue';
import { ChillFactorApi } from '../ChillFactor/ChillFactorApi';
import ShareScoreModal from '@/Shared/ShareScoreModal.vue';
import {
  makePng,
  imageDataUrl,
  loadingImage,
} from '~/composeables/shareChillFactor';
import TierProgressBar from './TierProgressBar.vue';
import SnowAnimation from '../../Shared/SnowAnimation.vue';
import RainAnimation from '@/Shared/RainAnimation.vue';

const api = new ChillFactorApi();
const myAddress = ref(JSON.parse(localStorage.getItem('my_address')) || '');
const chillFactorTooltipText = ref(
  'This is a snapshot of your Chill Factor profile, including your tier, rank and points earned. You can also see the contributions that your Participation, Consistency, and Diversity scores make to your total points earned.',
);
// MY CHILL FACTOR PROGRESS BARS
const mcf_progressBarHeight = ref('20%'); // Initial progress bar height
let mcf_progressInterval = null; // Interval reference
const mcf_progressRate = 5; // Progress rate in milliseconds
const mcf_maxProgress = 100; // Maximum progress
let mcf_currentProgress = 0; // Current progress

const mcf_startProgress = () => {
  // Reset progress if already started
  mcf_currentProgress = 0;
  mcf_progressBarHeight.value = '0%';

  // Start the progress interval
  mcf_progressInterval = setInterval(() => {
    mcf_currentProgress += 1;
    mcf_progressBarHeight.value = `${
      (mcf_currentProgress / mcf_maxProgress) * 100
    }%`;

    // Check if progress has reached maximum
    if (mcf_currentProgress >= mcf_maxProgress) {
      clearInterval(mcf_progressInterval);
    }
  }, mcf_progressRate);
};

function showBars(score, minTierName, currTierName) {
  // tier_cutoffs
  let tierCutOff = JSON.parse(localStorage.getItem('tier_cutoff'));
  if (checkpointStatsHistorical.value?.current_snapshot?.tier_cutoffs) {
    let minThreshold = minTierName ? tierCutOff[minTierName] : -9999;
    let maxThreshold = tierCutOff[currTierName];
    return score > maxThreshold
      ? true
      : score > minThreshold && score <= maxThreshold;
  }
}

function calculateHeight(score, min, max) {
  if (score > max) return '100%';
  const percentageChange = ((score - min) / (max - min)) * 100;
  const roundedPercentage = Math.round(percentageChange * 100) / 100;

  return `${roundedPercentage}%`;
}

const showLevelModal = ref(false);
// fetch Checkpoints and historical data
onMounted(() => {
  if (myAddress.value?.length > 0) {
    Promise.all([fetchAddress()]);
    const currentDate = new Date().toDateString();
    const showModalKey = `level_${myAddress.value}_${currentDate}`;
    if (localStorage.getItem('level_acheivement') != showModalKey) {
      showLevelModal.value = true;
      localStorage.setItem('level_acheivement', showModalKey);
    }
  }
});

// Modal
const showModal = ref(false);

function openModal() {
  showModal.value = true;
  document.body.classList.add('overflow-y-hidden');
}

function closeModal() {
  showModal.value = false;
  document.body.classList.remove('overflow-y-hidden');
}

function closeLevelModal() {
  showLevelModal.value = false;
  document.body.classList.remove('overflow-y-hidden');
}

function enteredAddress(e) {
  window.location.reload();
}

const loading = ref(true);
const checkpointStatsHistorical = ref([]);

async function fetchAddress() {
  loading.value = true;
  checkpointStatsHistorical.value = await api.fetchStatsHistoricalData();
}

// For Stats
const currentSnapShot = computed(() => {
  let formatterSnapshot = {
    current_snapshot: checkpointStatsHistorical.value?.current_snapshot,
    most_recent_components:
      checkpointStatsHistorical.value?.most_recent_components,
    most_recent_metrics: checkpointStatsHistorical.value?.most_recent_metrics,
    leaderboard_count:
      checkpointStatsHistorical.value?.total_numbers_from_leaderboard,
    components_historical:
      checkpointStatsHistorical.value?.components_historical,
  };
  if (checkpointStatsHistorical.value?.current_snapshot?.tier_cutoffs) {
    localStorage.setItem(
      'tier_cutoff',
      JSON.stringify(
        setMinMaxTierCutOff(
          checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs,
        ),
      ),
    );
  }
  calculateRange();
  loading.value = false;
  return formatterSnapshot;
});

// For Checkpoint
const checkpointSnapShot = computed(() => {
  let formatterSnapshot = {
    metric_checkpoints: checkpointStatsHistorical.value?.metric_checkpoints,
    most_recent_metrics: checkpointStatsHistorical.value?.most_recent_metrics,
    cf_by_checkpoints: checkpointStatsHistorical.value?.cf_by_checkpoints,
    metrics_historical: checkpointStatsHistorical.value?.metrics_historical,
    checkpoint_descriptions:
      checkpointStatsHistorical.value?.checkpoint_descriptions,
    recent_two_metrics_historical:
      checkpointStatsHistorical.value?.recent_two_metrics_historical,
  };
  return formatterSnapshot;
});

// SHARE MODAL
const openShareModal = ref(false);
const closeShareModal = () => {
  document.body.classList.remove('overflow-y-hidden');
  openShareModal.value = false;
};

// Direct share on Twitter
import { uploadedImageUrl } from '@/composeables/shareChillFactor';
import CrownIcon from '../../icons/CrownIcon.vue';

const generatingLink = ref(false);
const generatingLinkFor = ref(null);
const shareableLink = ref(null);
const imageBlob = ref(null);
const imageLoading = ref(null);

const shareOnTwitter = async () => {
  imageLoading.value = true;
  let linkFor = 'twitter';
  const elem = document.getElementById('chill-factor-score-card-2');
  let dimensions = { height: elem.clientHeight, width: elem.clientWidth };
  await makePng(
    'chill-factor-score-card-2',
    null,
    false,
    dimensions,
    'chill-factor-score-card-2',
  );
  generatingLink.value = true;
  generatingLinkFor.value = linkFor;
  const file = await makeImageFile(uploadedImageUrl.value);
  await uploadChillFactor(file, linkFor);
};
const makeImageFile = async (dataUrl) => {
  let fetchUrl = await fetch(dataUrl);
  imageBlob.value = await fetchUrl.blob();
  return new File([imageBlob.value], 'chillFactor', { type: 'image/png' });
};
const uploadChillFactor = async (file, linkFor) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const data = await api.uploadImage(formData);
    generatingLink.value = false;
    shareableLink.value = data.url;
    switch (linkFor) {
      case 'twitter':
        makeTwitterUrl();
        generatingLinkFor.value = null;
        break;
    }
  } catch (e) {
    console.error(e);
  }
};
const twitterDescription = computed(() => {
  return `I just checked my Avalanche Chill Factor score on FrostyMetrics and am ${checkpointStatsHistorical.value?.current_snapshot?.tier.toUpperCase()} with a score of ${
    checkpointStatsHistorical.value?.current_snapshot?.score
  }. Are you chiller than me?

To check your Chill Factor visit Frosty Metrics: https://frostymetrics.com/
`;
});
const makeTwitterUrl = () => {
  if (shareableLink.value.length) {
    const twitterPostIntentUrl = new URL('https://twitter.com/intent/tweet?');
    twitterPostIntentUrl.searchParams.append('url', shareableLink.value);
    twitterPostIntentUrl.searchParams.append('text', twitterDescription.value);
    imageLoading.value = false;
    window.open(
      twitterPostIntentUrl.href,
      'Share Chill Factor',
      'width=700,height=650',
    );
  }
};

function calculateRange() {
  if (selectedConfig.value) {
    if (selectedConfig.value.id == 'warm') {
      chillFactorConfig.find(
        (x) => x.id == 'warm',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs['warm'],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'lukewarm'
        ],
      )}`;
    } else if (selectedConfig.value.id == 'lukewarm') {
      chillFactorConfig.find(
        (x) => x.id == 'lukewarm',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'lukewarm'
        ],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'chilly'
        ],
      )}`;
    } else if (selectedConfig.value.id == 'chilly') {
      chillFactorConfig.find(
        (x) => x.id == 'chilly',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'chilly'
        ],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'frozen'
        ],
      )}`;
    } else if (selectedConfig.value.id == 'frozen') {
      chillFactorConfig.find(
        (x) => x.id == 'frozen',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'frozen'
        ],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'subzero'
        ],
      )}`;
    } else if (selectedConfig.value.id == 'subzero') {
      chillFactorConfig.find(
        (x) => x.id == 'subzero',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'subzero'
        ],
      )}`;
    }
  }
}

const selectedConfig = computed(() => {
  const tier = setTier(
    checkpointStatsHistorical.value?.current_snapshot?.score,
  );
  return (
    chillFactorConfig.find((item) => item.id === tier?.toLowerCase()) || {}
  );
});
</script>

<style scoped>
.my-chill-progress-container higher_chillFactor {
  width: 100%;
  position: relative;
}

.my-chill-progress-bar {
  position: absolute;
  bottom: 0;
  transition: height 0.1s; /* Optional: add transition effect */
}
</style>
