<template>
  <div class="flex h-full w-full">
    <div
      v-if="!topHolders"
      class="flex h-full w-full items-center justify-center"
    >
      <IconLoading class="h-12 w-12" />
    </div>
    <Table
      :config="topHoldersTableConfig"
      :data="topHoldersTableData"
      :key="topHoldersTableData?.length"
      v-else
    ></Table>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import Table from '../../Shared/Table.vue';
import { NftApi } from '../Nfts/NftApi';
import _ from 'lodash';
import IconLoading from '../../icons/IconLoading.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
const api = new NftApi();
const loading = ref(true);
const topHolders = ref([]);
onMounted(async () => {
  if (!_.isEmpty(props.collection)) {
    topHolders.value = await fetchTopHolders();
  }
});

const props = defineProps({
  collection: Object,
});

async function fetchTopHolders() {
  return api.fetchCollectionTopHolders(props.collection?.collection);
}

// Latest Trades
const topHoldersTableConfig = ref({
  cols: [
    {
      type: 'addresses',
      name: 'Address',
      id: 'address',
      textLeft: 'left',
      style: 'text-left',
      noSeparator: true,
    },
    { type: 'number', name: 'Number Held', id: 'number_held' },
    { type: 'percent', name: '1D Change', id: 'one_day_change' },
    {
      type: 'percent',
      name: '7D Change',
      id: 'seven_day_change',
      showZero: true,
    },
    { type: 'percent', name: '30D Change', id: 'thirty_day_change' },
  ],
  paddingLeftRight: 'px-3',
});

const noData = ref(false);

const topHoldersTableData = computed(() => {
  let data = [];
  if (typeof topHolders?.value == String) {
    noData.value = true;
  } else {
    topHolders?.value.map((item) => {
      data.push({
        address: item.collection,
        number_held: item.number_held,
        one_day_change: item.one_day_change,
        seven_day_change: item.seven_day_change,
        thirty_day_change: item.thirty_day_change,
      });
    });
  }
  loading.value = false;

  return data;
});
</script>
