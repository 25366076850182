<template>
  <div class="">
    <DefiHeader :token="token" />

    <section
      class="w-full grid-cols-10 gap-4 space-y-4 lg:grid lg:h-[28rem] lg:space-y-0"
    >
      <BaseCard class="col-span-7">
        <template #header>Activity Growth</template>
        <template #controls>
          <BaseRadio
            v-model="selectedTimeframe"
            multiple
            button-width-class="w-32"
            :options="timeframes"
            placeholder="Select Type"
          />
        </template>
        <div
          class="col-span-6 flex h-full flex-shrink flex-grow flex-col rounded sm:py-4"
        >
          <div class="flex-1 overflow-hidden rounded-lg shadow">
            <MultiLineChart
              :series="chartSeries"
              :config="chartConfig"
              :key="`${selectedTimeframe}_${activityGrowthSeries.length}`"
            />
          </div>
        </div>
      </BaseCard>

      <!-- NewsSection  -->
      <BaseCard class="col-span-3">
        <template #header>Latest News</template>
        <!-- <div class="flex flex-col overflow-x-auto rounded py-3 px-3"> -->
        <!-- <div class="flex items-center text-base text-textColor">
            <span class="mr-1 sm:py-2 lg:text-lg">Latest News</span>
            <Tooltip
              text="TVL data provided by DeFiLlama"
              id="dashboard_ecosystem_tvls"
            >
              <InformationCircleIcon class="h-3.5 w-3.5 text-infoIconColor" />
            </Tooltip>
          </div> -->
        <div class="wrap h-96 space-y-2 overflow-y-auto">
          <div v-if="newsLoading">
            <div v-for="i in 5" :key="i">
              <NewsTemplate />
            </div>
          </div>
          <div v-if="newsItems.length > 0">
            <div
              v-for="item in newsItems"
              :key="item.id"
              class="flex h-[7rem] items-center border-b border-gray-700"
            >
              <NewsWidget :news-item="item" />
            </div>
          </div>
          <div
            v-else-if="!newsLoading && newsItems"
            class="mt-auto flex h-full w-full justify-center rounded-lg bg-avalanche-gray"
          >
            <NoDataFound />
          </div>
        </div>
        <!-- </div> -->
      </BaseCard>
    </section>

    <section
      class="grid w-full grid-cols-1 gap-4 space-y-4 py-4 lg:grid-cols-6 lg:space-y-0"
    >
      <div
        class="col-span-1 w-full flex-col justify-between md:col-span-2 md:flex lg:col-span-1"
      >
        <BaseCard>
          <template #header>Key Metrics</template>
          <div class="h-full items-center space-y-2 rounded px-1">
            <span class="flex justify-between">
              <span class="text-sm">Cumulative Transactions</span>
              <span>{{ rounded(cumulativeTransactions, 0) }}</span>
            </span>
            <span class="flex justify-between">
              <span class="text-sm">Cumulative Wallets</span>
              <span>{{ rounded(cumulativeWallets, 0) }}</span>
            </span>
            <span class="flex justify-between">
              <span class="text-sm">TVL</span>
              <span>${{ formatNumber(getLatestTvl, 2) }}</span>
            </span>
            <span class="flex justify-between">
              <span class="text-sm"> Market Cap</span>
              <span>${{ formatNumber(token.mcap, 2) }}</span>
            </span>
          </div>
        </BaseCard>
        <div class="">
          <div class="py-3 font-semibold sm:text-lg">Tokens</div>
          <TokenCard :token="token" class="flex items-center" />
        </div>
      </div>

      <BaseCard class="col-span-1 lg:col-span-5">
        <template #header>TVL</template>
        <template #controls>
          <BaseRadio
            v-model="selectedTimeframeTVL"
            multiple
            button-width-class="w-32"
            :options="timeframesTVL"
            placeholder="Select Type"
          />
        </template>
        <div class="flex w-full flex-shrink flex-col rounded py-3 px-3">
          <div class="relative overflow-x-auto rounded">
            <LineChart
              :series="tvlSeriesComputed"
              :config="tvlChartConfig"
              :key="selectedTimeframeTVL"
            />
          </div>
        </div>
      </BaseCard>
    </section>

    <section>
      <AvailablePairs :token="token" />
    </section>
  </div>
</template>

<script setup>
import NewsWidget from '../../Widgets/News.vue';
import { computed, ref, watch, onMounted, inject, reactive } from 'vue';
import {
  formatNumber,
  rounded,
  percentage,
} from '../../composeables/filters.js';
import LineChart from '../../Widgets/LineChart.vue';
import MultiLineChart from '../../Widgets/MultiLineChart.vue';
import NewsTemplate from '../../load_templates/NewsTemplateCard.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import TokenCard from '../../Shared/TokenCard.vue';
import Tooltip from '../../Shared/Tooltip.vue';
import Percent from '../../Shared/Percent.vue';
import { filterToDateTime, getRandomId } from '../../composeables/filters.js';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import moment from 'moment';
import DefiHeader from '../../Shared/DefiHeader.vue';
import AvailablePairs from './AvailablePairs.vue';
import BaseRadio from '../../Shared/BaseRadio.vue';
import { DefiApi } from './DefiApi';
import BaseCard from '../../Shared/BaseCard.vue';
import Table from '../../Shared/Table.vue';
const timeframes = ['7D', '30D', '3M', '6M', '1Y', 'ALL'];
const timeframesTVL = ['7D', '30D', '3M', '6M', '1Y', 'ALL'];
const selectedTimeframe = ref('30D');
const selectedTimeframeTVL = ref('30D');
const api = new DefiApi();

const props = defineProps({
  token: Object,
});
const newsItems = ref([]);
const activityGrowthSeries = ref([]);

onMounted(() => {
  Promise.all([fetchDappActivityData(), fetchNews(), tvlChartData()]);
});

async function fetchDappActivityData() {
  activityGrowthSeries.value = await api.fetchDappActivityData({
    dapp: props.token?.defi_uid,
  });
  calculateKeyMetrics.value;
}

const newsLoading = ref('true');
async function fetchNews() {
  newsItems.value = await api.fetchNews(props.token.ticker);
  newsLoading.value = false;
}

const chartConfig = ref({
  yAxis: [
    {
      // Transactions
      labels: {
        style: {
          color: '#9CA3AF',
        },
      },
      title: {
        text: 'Wallet Count',
        style: {
          color: '#9CA3AF',
        },
      },
      opposite: true,
      gridLineColor: '#262626',
    },
    {
      // Wallets
      gridLineWidth: 0,
      title: {
        text: 'Transaction Count',
        style: {
          color: '#10b981',
        },
      },

      labels: {
        style: {
          color: '#10b981',
        },
      },
      gridLineColor: '#262626',
    },
    {
      // New Wallets
      gridLineWidth: 0,
      title: {
        text: '',
      },
      labels: {
        style: {
          color: '#9CA3AF',
        },
      },
      opposite: true,
      gridLineColor: '#262626',
    },
  ],
  // useUTC: true,
});

const chartSeries = computed(() => {
  return [
    {
      name: 'Transactions',
      type: 'line',
      yAxis: 1,
      data: transactionSeries.value,
      tooltip: {
        valueSuffix: '',
      },
      marker: {
        enabled: false,
      },
      color: '#10b981',
      animation: false,
    },
    {
      name: 'Wallets',
      type: 'line',
      yAxis: 0,
      data: walletSeries.value,
      tooltip: {
        valueSuffix: '',
      },
      marker: {
        enabled: false,
      },
      color: '#3b82f6',
      animation: false,
    },
    {
      name: 'New Wallets',
      type: 'line',
      yAxis: 0,
      data: newWalletSeries.value,
      marker: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: '',
      },
      color: '#f59e0b',
      animation: false,
    },
  ];
});

function associatedTokenRecords() {
  let filterValue = filterToDateTime(selectedTimeframe.value, true);
  filterValue =
    filterValue == 'ALL' ? activityGrowthSeries.value.length : filterValue;
  return activityGrowthSeries.value
    .filter((x) => x.dapp.lowercase == props.token?.name.lowercase)
    .slice(0, parseInt(filterValue));
}

const transactionSeries = computed(() => {
  return associatedTokenRecords().map((x) => {
    return [
      moment(moment.utc(x.date).format('YYYY-MM-DD')).unix() * 1000,
      parseFloat(x.num_transactions),
    ];
  });
});
const walletSeries = computed(() => {
  return associatedTokenRecords().map((x) => {
    return [
      moment(moment.utc(x.date).format('YYYY-MM-DD')).unix() * 1000,
      parseInt(x.unique_wallets),
    ];
  });
});
const newWalletSeries = computed(() => {
  return associatedTokenRecords().map((x) => {
    return [
      moment(moment.utc(x.date).format('YYYY-MM-DD')).unix() * 1000,
      parseInt(x.new_users),
    ];
  });
});

const tvlChartConfig = ref({
  type: 'area',
  name: 'Total Value Locked (USD)',
  opacity: 0.3,
  height: '320',
  fillColor: {
    linearGradient: [0, 0, 0, 300],
    stops: [
      [0, '#24395E'],
      [1, '#1A1A1c'],
    ],
  },
});

const tvlSeries = reactive({});
async function tvlChartData() {
  tvlSeries.value = await api.fetchTvlSingleChartData(props.token?.defi_uid);
}
const getLatestTvl = ref(0);
const tvlSeriesComputed = computed(() => {
  let data = [];
  tvlSeries.value
    ?.filter((x) =>
      selectedTimeframeTVL.value == 'ALL'
        ? true
        : moment(x.date * 1000).format() >=
          filterToDateTime(selectedTimeframeTVL.value),
    )
    .map((x) => {
      return data.push([moment(x.date) * 1000, parseInt(x.totalLiquidityUSD)]);
    });
  getLatestTvl.value = data.at(-1)?.at(-1);
  return data;
});

const cumulativeTransactions = ref(0);
const cumulativeWallets = ref(0);

function addition(data) {
  return data.reduce((a, b) => {
    return a + b;
  }, 0);
}

const calculateKeyMetrics = computed(() => {
  let requiredData = activityGrowthSeries.value?.filter(
    (x) => x.dapp.lowercase == props.token?.name.lowercase,
  );

  // cumulativeTransactions
  let cTransactions = requiredData.map((x) => parseFloat(x.num_transactions));
  cumulativeTransactions.value = addition(cTransactions);

  // cumulativeWallets
  let cWallets = requiredData.map((x) => parseFloat(x.new_users));
  cumulativeWallets.value = addition(cWallets);

  return selectedTimeframeTVL.value;
});
</script>
