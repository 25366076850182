<template>
  <div class="mx-auto text-sm">
    <!-- stats with Line graph section -->

    <section>
      <div
        class="w-full space-y-4 py-4 md:items-center lg:flex lg:space-x-4 lg:space-y-0"
      >
        <div class="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-3">
          <div
            class="w-full"
            v-for="item in statsItems"
            :key="item.id"
            id="lineGraphContainer"
          >
            <StatsWidget
              :stat-item="item"
              :show-loading="statsWidgetLoading"
              height=""
            />
          </div>
        </div>
      </div>
    </section>

    <!-- table section -->
    <TopProjects />

    <!-- Dex's & Trading Volume section -->
    <Dex />

    <!-- graph section  -->
    <section>
      <div
        class="w-full gap-4 space-y-4 md:grid md:grid-cols-2 md:space-y-0 lg:grid-cols-3"
      >
        <GraphCard
          type="pie"
          title="Transactions"
          page="defi"
          :data-series="transactionsGraph"
          :sort-by="{ column: 'y', order: 'desc' }"
          @filter-changed-emit="(x) => (transactionsSelected = x.filterVal)"
        />
        <GraphCard
          type="bar"
          title="Wallets"
          page="defi"
          :data-series="walletsGraph"
          :sort-by="{ column: 'value', order: 'desc' }"
          @filter-changed-emit="(x) => (walletsSelected = x.filterVal)"
        />
        <GraphCard
          type="bar"
          title="New Wallets"
          page="defi"
          :data-series="newwalletsGraph"
          :sort-by="{ column: 'value', order: 'desc' }"
          @filter-changed-emit="(x) => (newwalletsSelected = x.filterVal)"
        />
      </div>
    </section>
  </div>
</template>
<script setup>
import { ref, onMounted, watch, computed, inject } from 'vue';
import StatsWidget from '../../Widgets/Stats.vue';
import TopProjects from './Index/TopProjects.vue';
import Dex from './Index/Dex.vue';
import Table from '../../Shared/Table.vue';
import Tooltip from '../../Shared/Tooltip.vue';
import GraphCard from '../../Shared/GraphCard.vue';
import IndividualPageLineChart from '../../Widgets/LineChart.vue';
import BarGraph from '../../Widgets/BarChart.vue';
import IconLoading from '../../icons/IconLoading.vue';
import { filterToDateTime, getRandomId } from '../../composeables/filters.js';
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
import BaseCard from '../../Shared/BaseCard.vue';
import moment from 'moment';
import _ from 'lodash';
import { DefiApi } from './DefiApi';
const myAddress = ref(JSON.parse(localStorage.getItem('my_address')) || '');
const api = new DefiApi();

onMounted(() => {
  Promise.all([fetchEcosystemActivityData(), fetchDappActivityData()]);
});

const ecosystemActivityData = ref([]);
const uniqueWallets = ref({});
async function fetchEcosystemActivityData() {
  let activityData = await api.fetchEcosystemActivityData();
  ecosystemActivityData.value = activityData?.data;
  uniqueWallets.value = activityData?.unique_wallets;
}
const statsWidgetLoading = ref(true);
const statsItems = computed(() => {
  let stats = [
    { id: 1, title: 'Transactions', total: 0, from: 0, growth: 0 },
    { id: 2, title: 'Wallets', total: 0, from: 0, growth: 0 },
    { id: 3, title: 'New Wallets', total: 0, from: 0, growth: 0 },
  ];
  let res = ecosystemActivityData.value;
  if (Object.keys(res).length > 0) {
    ['daily_transactions', 'daily_users', 'new_users'].forEach((x, i) => {
      if (x == 'daily_users') {
        stats[i].total = uniqueWallets.value.total;
        stats[i].from = uniqueWallets.value.from;
        stats[i].growth = uniqueWallets.value.growth;
      } else {
        stats[i].total = res
          .slice(0, 30)
          ?.reduce((partialSum, a) => partialSum + a[x], 0);
        stats[i].from = res
          .slice(30, 60)
          ?.reduce((partialSum, a) => partialSum + a[x], 0);
        stats[i].growth = api.calculateChange(stats[i].total, stats[i].from);
      }
      stats[i].chart = res?.slice(0, 30).map((c) => {
        return [new Date(c.date).getTime(), parseFloat(c[x]) || 0];
      });
    });
    statsWidgetLoading.value = false;
  }
  return stats;
});

const universeDefiTokens = inject('universeDefiTokens');

const dappActivityData = ref([]);
async function fetchDappActivityData() {
  dappActivityData.value = await api.fetchDappActivityData({ limit: 30 });
}
const transactionsSelected = ref('24H');
const transactionsGraph = computed(() => {
  return setupGraphs(transactionsSelected.value, 'num_transactions');
});
const walletsSelected = ref('24H');
const walletsGraph = computed(() => {
  return setupGraphs(walletsSelected.value, 'unique_wallets');
});
const newwalletsSelected = ref('24H');
const newwalletsGraph = computed(() => {
  return setupGraphs(newwalletsSelected.value, 'new_users');
});
function setupGraphs(selectedFilter, column) {
  let value = selectedFilter == '24H' ? '1D' : selectedFilter;
  let data = [];
  let latestRecord = dappActivityData.value[0];
  let filteredData = dappActivityData.value.filter(
    (x) =>
      moment(x.date).utc().format() >=
      filterToDateTime(
        value,
        false,
        moment(latestRecord.date).utc().add(1, 'days'),
      ),
  );
  let total_volume = 0;
  if (column == 'num_transactions') {
    total_volume = filteredData?.reduce(
      (partialSum, a) => partialSum + a[column],
      0,
    );
  }

  if (filteredData.length > 0) {
    let grouped = _.groupBy(filteredData, 'dapp');
    Object.keys(grouped)?.forEach((k) => {
      let token_total = grouped[k]?.reduce(
        (partialSum, a) => partialSum + a[column],
        0,
      );
      let tempData = {
        name:
          k == 'benqi_finance'
            ? 'Benqi'
            : k == 'gmx'
            ? 'GMX'
            : k == 'woofi'
            ? 'WOOfi'
            : k
                .replace(/_/g, ' ')
                .replace(/(^|\s)\S/g, (match) => match.toUpperCase()),
      };
      if (column == 'num_transactions') {
        tempData['y'] = token_total / total_volume;
      } else {
        tempData['data'] = [token_total];
        tempData['value'] = token_total;
      }
      data.push(tempData);
    });
  }
  return data;
}
</script>
