<template>
  <div class="mx-auto text-sm">
    <!-- stats with Line graph section -->
    <section>
      <div
        class="w-full space-y-4 py-4 md:items-center lg:flex lg:space-x-4 lg:space-y-0"
      >
        <div class="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-3">
          <div class="w-full" v-for="item in statsItems" :key="item.id">
            <StatsWidget
              :dollar-sign="item.dollarSign"
              :stat-item="item"
              height=""
            />
          </div>
        </div>
      </div>
    </section>

    <!-- table section -->
    <section class="lg:flex lg:space-x-4">
      <BaseCard
        class="flex w-full flex-shrink-0 flex-grow flex-col rounded pt-3 md:h-80 lg:h-[28rem] lg:w-7/12"
      >
        <div class="pb-1 font-semibold lg:text-lg">Top Projects</div>
        <!-- Tabs options   -->
        <div
          class="flex space-x-1.5 border-b-2 border-[#f3f2f614] pt-3 text-base font-medium sm:space-x-2 lg:space-x-4"
        >
          <div
            v-for="tab in tabOptions"
            :key="tab.id"
            @click="selectedTab = tab.id"
            class="-mb-[2px] cursor-pointer border-b-2 px-2 pb-2"
            :class="
              tab.id == selectedTab
                ? 'border-b-2 border-blue-400 text-white'
                : 'border-transparent text-[#6c757b]'
            "
          >
            <span v-if="tab.id != 'active_wallets'">{{ tab.title }}</span>
            <span v-else class="flex items-center justify-center">
              {{ tab.title }}
              <Tooltip
                class="ml-1"
                text="Data is updates weekly and monthly on the 1st of each week and month."
              >
                <InformationCircleIcon class="h-3.5 w-3.5" />
              </Tooltip>
            </span>
          </div>
        </div>
        <div
          class="wrap mt-4 h-72 flex-1 overflow-hidden overflow-x-auto overflow-y-auto rounded-lg text-xs shadow"
        >
          <div class="h-72 w-full font-semibold md:h-36" style="color: #a3a3a3">
            <template v-if="selectedTab == 'total_stats'">
              <Table
                :config="totalStatsTableConfig"
                :data="
                  _.orderBy(
                    tableMarketCap,
                    'total_game_num_transactions',
                    'desc',
                  )
                "
              >
              </Table>
            </template>

            <template v-if="selectedTab == 'active_wallets'">
              <Table
                :config="activeWalletsTableConfig"
                :data="_.orderBy(gamingData, 'unique_wallets_one_day', 'desc')"
              ></Table>
            </template>

            <template v-if="selectedTab == 'transactions'">
              <Table
                :config="transactionsTableConfig"
                :data="
                  _.orderBy(gamingData, 'num_transactions_one_day', 'desc')
                "
              ></Table>
            </template>
          </div>
        </div>
      </BaseCard>

      <!-- NewsSection  -->
      <div
        class="flex w-full flex-shrink flex-grow flex-col overflow-x-auto rounded lg:w-5/12"
      >
        <BaseCard
          class="start-col-6 col-span-2 flex w-full flex-shrink flex-grow flex-col overflow-x-auto rounded"
        >
          <template #header>Latest News</template>
          <div class="wrap h-96 space-y-2 overflow-y-auto">
            <div v-if="newsLoading">
              <div v-for="i in 5" :key="i">
                <NewsTemplate />
              </div>
            </div>
            <div v-if="newsItems.length > 0">
              <div
                v-for="item in newsItems"
                :key="item.id"
                class="flex h-[7rem] items-center border-b border-gray-700 py-1"
              >
                <NewsWidget :news-item="item" />
              </div>
            </div>
            <div
              v-else-if="!newsLoading && newsItems"
              class="mt-auto flex h-full w-full justify-center"
            >
              <NoDataFound />
            </div>
          </div>
        </BaseCard>
      </div>
    </section>

    <!-- graph section  -->
    <section>
      <div
        class="w-full gap-4 space-y-4 py-4 md:grid md:grid-cols-2 lg:grid-cols-3 lg:space-y-0"
      >
        <GraphCard
          type="pie"
          title="Total Transactions"
          :sort-by="{ column: 'y', order: 'desc' }"
          :data-series="_.orderBy(gamingData, 'game', 'asc')"
        />
        <GraphCard
          type="bar"
          title="Total Wallets"
          :sort-by="{ column: 'value', order: 'desc' }"
          :data-series="_.orderBy(gamingData, 'game', 'asc')"
        />
        <GraphCard
          type="pie"
          page="gaming"
          title="$MarketCap"
          :sort-by="{ column: 'y', order: 'desc' }"
          :data-series="_.orderBy(marketCapPieChartData, 'game', 'asc')"
          :config="{
            legend: true,
            pieFormatType: 'number',
            'dollar-sign': '$',
          }"
          @filter-changed-emit="(x) => fetchFilterBaseData(x)"
        />
      </div>
    </section>
  </div>
</template>

<script setup>
import GraphCard from '../../Shared/GraphCard.vue';
import StatsWidget from '../../Widgets/Stats.vue';
import BaseCard from '../../Shared/BaseCard.vue';
import PieGraphWidget from '../../Widgets/PieChart.vue';
import NewsWidget from '../../Widgets/News.vue';
import NewsTemplate from '../../load_templates/NewsTemplateCard.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import _ from 'lodash';
import { sortBy } from 'lodash';
import { ref, onMounted, watch, reactive, computed } from 'vue';
import Table from '../../Shared/Table.vue';
import { DashboardApi } from './DashboardApi';
const myAddress = ref(JSON.parse(localStorage.getItem('my_address')) || '');
import Tooltip from '../../Shared/Tooltip.vue';
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
import { sortData } from '../../composeables/filters.js';

const api = new DashboardApi();

const chartLoading = ref(true);
const ecosystemData = ref({});
const gamingData = ref([]);
const nftData = ref([]);
const newsItems = ref([]);

onMounted(() => {
  Promise.all([
    fetchEcosystemData(),
    fetchGamingData(),
    fetchNftData(),
    fetchGamingMarketCapData(),
    fetchNews(),
  ]);
});

async function fetchEcosystemData() {
  ecosystemData.value = await api.fetchEcosystemData();
  chartLoading.value = false;
}
async function fetchGamingData() {
  gamingData.value = await api.fetchGamingData();
}

const gaming_market_cap = ref([]);
async function fetchGamingMarketCapData() {
  gaming_market_cap.value = await api.fetchGamingMarketCapData();
}

const tableMarketCap = computed(() => {
  let tableMcap = [];
  gamingData.value.forEach((item) => {
    const mcapItem = gaming_market_cap.value?.latestMarketCap?.find(
      (mcap_item) => item.game === mcap_item.game,
    );
    if (mcapItem) {
      tableMcap.push({
        ...item,
        marketCap: mcapItem?.market_cap,
      });
    } else {
      tableMcap.push({ ...item });
    }
  });
  fetchFilterBaseData(selectedFilter.value);
  return tableMcap;
});

async function fetchNftData() {
  nftData.value = await api.fetchNftData();
}

const newsLoading = ref('true');
async function fetchNews() {
  newsItems.value = await api.fetchNews();
  newsLoading.value = false;
}

watch(ecosystemData, async (newVal, oldVal) => {
  let data = ecosystemData.value;
  statsItems.value.map((item) => {
    if (item.title == 'Total Transactions') {
      item.total = data.ecosystem_num_transactions_thirty_day;
      item.from = data.ecosystem_num_transactions_previous_thirty_day;
      item.growth = data.ecosystem_num_transactions_growth_thirty_day;
      item.chart = data.transactions?.sort()?.reverse();
    } else if (item.title == 'MarketCap') {
      item.total = data?.total_market_cap[0]['to'];
      item.from = data?.total_market_cap[0]['from'];
      item.growth = data?.total_market_cap[0]['growth'];
      item.chart = data?.total_market_cap[0]['total_market_cap_series'];
    } else if (item.title == 'Total Wallets') {
      item.total = data.ecosystem_unique_wallets_thirty_day;
      item.from = data.ecosystem_unique_wallets_previous_thirty_day;
      item.growth = data.ecosystem_unique_wallets_growth_thirty_day;
      item.chart = data.wallets?.sort()?.reverse();
    }
  });
});

const statsItems = ref([
  { id: 1, title: 'Total Transactions', total: 0, from: 0, growth: 0 },
  { id: 2, title: 'Total Wallets', total: 0, from: 0, growth: 0 },
  {
    id: 3,
    title: 'MarketCap',
    total: 0,
    from: 0,
    growth: 0,
    dollarSign: true,
    usd: true,
    tooltipText: 'no data for unverified projects',
  },
]);

const activeWalletsTableConfig = ref({
  cols: [
    { type: 'game_api', name: 'Game', id: 'game_api', textLeft: 'left' },
    { type: 'number', name: 'Daily', id: 'unique_wallets_one_day' },
    {
      type: 'percent',
      name: 'Daily Growth',
      id: 'unique_wallets_growth_one_day',
    },
    { type: 'number', name: 'Weekly', id: 'unique_wallets_seven_day' },
    {
      type: 'percent',
      name: 'Weekly Growth',
      id: 'unique_wallets_growth_seven_day',
    },
    { type: 'number', name: 'Monthly', id: 'unique_wallets_thirty_day' },
    {
      type: 'percent',
      name: 'Monthly Growth',
      id: 'unique_wallets_growth_thirty_day',
    },
  ],
  paddingLeftRight: 'px-3',
});

const totalStatsTableConfig = ref({
  cols: [
    { type: 'game_api', name: 'Game', id: 'game_api', textLeft: 'left' },
    {
      type: 'number',
      name: 'Total Transactions',
      id: 'total_game_num_transactions',
    },
    {
      type: 'number',
      name: 'Total Wallets',
      id: 'total_game_unique_wallets',
    },
    { type: 'number', name: 'MarketCap', id: 'marketCap' },
  ],
  paddingLeftRight: 'px-3',
});

const transactionsTableConfig = ref({
  cols: [
    { type: 'game_api', name: 'Game', id: 'game_api', textLeft: 'left' },
    { type: 'number', name: '1d', id: 'num_transactions_one_day' },
    {
      type: 'percent',
      name: '1d Growth',
      id: 'num_transactions_growth_one_day',
    },
    { type: 'number', name: '7d', id: 'num_transactions_seven_day' },
    {
      type: 'percent',
      name: '7d Growth',
      id: 'num_transactions_growth_seven_day',
    },
    { type: 'number', name: '30d', id: 'num_transactions_thirty_day' },
    {
      type: 'percent',
      name: '30d Growth',
      id: 'num_transactions_growth_thirty_day',
    },
  ],
  paddingLeftRight: 'px-3',
});

const selectedTab = ref('total_stats');

const tabOptions = reactive([
  { id: 'total_stats', title: 'Total Stats' },
  { id: 'active_wallets', title: 'Active Wallets' },
  { id: 'transactions', title: 'Transactions' },
]);

const selectedFilter = ref('1D');
import { coinBaseColors } from '../../composeables/filters.js';
const marketCapPieChartData = ref({});

function fetchFilterBaseData(filter) {
  let filterValue = filter?.filterVal ? filter.filterVal : filter;
  filterValue = filterValue == '24H' ? '1D' : filterValue;
  let graphMcap = [];
  let selectedFilterValue = `mcap_day_${filterValue}`;
  gaming_market_cap.value[selectedFilterValue]?.map((item) => {
    if (item.coin_uid && filterValue) {
      graphMcap.push({
        name: item.game,
        y: item.market_cap ? parseFloat(item.market_cap) : null,
        value: item.market_cap ? parseFloat(item.market_cap) : null,
        color: coinBaseColors(item.game),
      });
    }
  });
  return (marketCapPieChartData.value = sortData(graphMcap, 'y').filter(
    (item) => item.y,
  ));
}
</script>
