<template>
  <BaseCard class="col-span-1 overflow-auto !p-0 !text-gray-300 md:h-full">
    <div
      class="items-center justify-between space-y-2 px-2 py-4 sm:flex sm:space-y-0"
    >
      <div class="flex items-center text-base font-normal text-gray-400">
        Leaderboard
        <Tooltip :text="leaderboardTooltipText" class="ml-1">
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </div>
      <div class="flex w-full flex-col items-end justify-end">
        <button
          @click="showDropdown = !showDropdown"
          class="flex items-center rounded-md text-xs font-semibold text-gray-500"
        >
          <div
            class="flex w-48 items-center justify-between space-x-2 rounded border border-gray-800 px-2 py-1"
          >
            <div class="flex items-center space-x-2 text-xs">
              <span class="text-gray-100 opacity-40 md:whitespace-nowrap"
                >Frosty Level:</span
              >
              <span class="mr-2 text-white">{{
                leaderboardSelected.title
              }}</span>
            </div>
            <svg
              class="flex-shrink-0"
              :class="showDropdown ? '' : 'rotate-180'"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M13.354 10.3541C13.3076 10.4006 13.2524 10.4375 13.1917 10.4627C13.131 10.4878 13.066 10.5008 13.0003 10.5008C12.9346 10.5008 12.8695 10.4878 12.8088 10.4627C12.7481 10.4375 12.693 10.4006 12.6465 10.3541L8.00028 5.70727L3.35403 10.3541C3.26021 10.448 3.13296 10.5007 3.00028 10.5007C2.8676 10.5007 2.74035 10.448 2.64653 10.3541C2.55271 10.2603 2.5 10.1331 2.5 10.0004C2.5 9.86771 2.55271 9.74046 2.64653 9.64664L7.64653 4.64664C7.69296 4.60015 7.74811 4.56328 7.80881 4.53811C7.86951 4.51295 7.93457 4.5 8.00028 4.5C8.06599 4.5 8.13105 4.51295 8.19175 4.53811C8.25245 4.56328 8.30759 4.60015 8.35403 4.64664L13.354 9.64664C13.4005 9.69308 13.4374 9.74822 13.4626 9.80892C13.4877 9.86962 13.5007 9.93469 13.5007 10.0004C13.5007 10.0661 13.4877 10.1312 13.4626 10.1919C13.4374 10.2526 13.4005 10.3077 13.354 10.3541Z"
                fill="#EEEEF0"
              />
            </svg>
          </div>
        </button>
        <div class="relative">
          <Dropdown
            :show="showDropdown"
            :items="dropdownItems"
            :configuration="configuration"
            @selected="(x) => (leaderboardSelected = x)"
            @close="showDropdown = false"
          />
        </div>
      </div>
    </div>
    <div class="h-full w-full">
      <div class="flex h-[19rem] flex-col">
        <div class="h-full w-full overflow-x-auto">
          <div class="h-full w-full">
            <div
              v-if="loading"
              class="flex h-full w-full items-center justify-center"
            >
              <div class="w-full">
                <div v-for="item in 10" :key="item">
                  <LeaderboardTemplateCard />
                </div>
              </div>
            </div>
            <div
              v-else-if="filteredLeader?.length == 0 && !loading"
              class="flex h-full items-center justify-center"
            >
              <NoDataFound />
            </div>
            <table
              class="relative min-w-full table-auto text-left text-xs"
              v-else
              style="font-family: 'Source Code Pro', monospace"
            >
              <thead
                class="sticky top-0 z-10 border-b border-[#f3f2f614] bg-[#f3f2f60a] text-xs text-[#EEEEF0] backdrop-blur-md"
              >
                <tr class="text-gray-400">
                  <th scope="col" class="px-2 py-4">Rank</th>
                  <th scope="col" class="px-2 py-4">Address</th>
                  <th scope="col" class="px-2 py-4">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in filteredLeader"
                  :key="item"
                  :class="[
                    myAddress == item.address
                      ? 'bg-[#FFD6003D]/10 font-bold text-yellow-500'
                      : '',
                    'w-full border-b border-[#f3f2f614] transition duration-300 ease-in-out hover:rounded-lg hover:bg-neutral-100 dark:border-slate-900 dark:hover:bg-gray-900',
                  ]"
                >
                  <td
                    class="w-8 whitespace-nowrap"
                    :class="[
                      item.rank == 1 ? 'bg-[#FFD6003D] text-xs font-bold' : '',
                      item.rank == 2 ? 'bg-[#6E87E33D] text-xs font-bold' : '',
                      item.rank == 3 ? 'bg-[#EF44443D] text-xs font-bold' : '',
                      'text-gray-400',
                    ]"
                  >
                    <div
                      class="flex items-center justify-center"
                      :class="[
                        item.rank == 1 ? 'text-[#fde047]' : '',
                        item.rank == 2 ? 'text-[#2563eb]' : '',
                        item.rank == 3 ? 'text-[#dc2626]' : '',
                      ]"
                    >
                      <TrophyIcon
                        class="h-3 w-3"
                        v-if="[1, 2, 3].includes(item.rank)"
                      />
                      <span class="px-1">{{
                        simpleNumberFormatter(item.rank)
                      }}</span>
                    </div>
                  </td>
                  <td
                    class="flex items-center overflow-hidden px-2 py-3 md:whitespace-nowrap"
                  >
                    <Tooltip
                      :text="item.address"
                      class="ml-1 flex items-center space-x-1"
                      v-if="
                        item.address.length >
                        (myAddress == item.address ? 15 : 28)
                      "
                    >
                      <span>
                        <span v-if="myAddress == item.address" class="sm:pr-2">
                          <span class="leading-5">ADDRESS RANK</span>
                          {{ truncate(item.address, 15) }}
                        </span>
                        <span v-else>{{ truncate(item.address, 28) }}</span>
                      </span>
                    </Tooltip>
                    <CheckIcon
                      v-if="addressCopied == item.address"
                      class="h-4 w-4 text-green-500"
                    />
                    <Tooltip
                      text="Copy Address"
                      class="ml-1 flex items-center space-x-1"
                    >
                      <IconCopy
                        v-if="addressCopied != item.address"
                        class="h-3.5 w-3.5 cursor-pointer text-[#F1F5F9]"
                        @click="copyToClipboard(item.address)"
                      />
                    </Tooltip>
                  </td>
                  <td class="max-w-12 whitespace-nowrap px-2 py-3">
                    {{ simpleNumberFormatter(item.score) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import {
  InformationCircleIcon,
  TrophyIcon,
  CheckIcon,
} from '@heroicons/vue/24/outline';
import BaseCard from '../../Shared/BaseCard.vue';
import Tooltip from '../../Shared/Tooltip.vue';
import Dropdown from '../../Shared/Dropdown.vue';
import IconLoading from '../../icons/IconLoading.vue';
import IconCopy from '../../icons/IconCopy.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import LeaderboardTemplateCard from '../../load_templates/LeaderboardTemplateCard.vue';
import { orderBy } from 'lodash';
import {
  truncate,
  simpleNumberFormatter,
  rounded,
} from '../../composeables/filters.js';

const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
});

const universeChillFactor = inject('universeChillFactor');
const leaderboardTooltipText = ref(
  'This is the Chill Factor leaderboard. You can view the top addresses globally, or in each tier.',
);
const myAddress = ref(JSON.parse(localStorage.getItem('my_address')) || '');
const showDropdown = ref(false);

const configuration = ref({
  classes: 'bg-[#f3f2f614] backdrop-blur-[12px] !h-56 box-shadow',
  textColor: 'text-[#f3f2f68f] hover:text-white',
});

const dropdownItems = ref([
  {
    id: 0,
    title: 'All',
  },
  {
    id: 1,
    title: 'Warm',
  },
  {
    id: 2,
    title: 'Lukewarm',
  },
  {
    id: 3,
    title: 'Chilly',
  },
  {
    id: 4,
    title: 'Frozen',
  },
  {
    id: 5,
    title: 'Subzero',
  },
]);

const leaderboardSelected = ref({ id: 0, title: 'All' });

const filteredLeader = computed(() => {
  let tier = leaderboardSelected.value.title;
  let data = [];
  if (
    universeChillFactor.value &&
    Object.keys(universeChillFactor.value).length > 0
  ) {
    if (tier == 'All') {
      data = orderBy(universeChillFactor.value['subzero'], ['rank']);
    } else
      data = orderBy(
        universeChillFactor.value[
          tier?.toLocaleLowerCase()?.replace(/\s/g, '')
        ],
        ['rank'],
      );

    if (tier?.toLocaleLowerCase()?.replace(/\s/g, '') == 'subzero')
      data = data.slice(0, 100);
  }

  localStorage.setItem('leaderboardSelectedOption', tier);
  return data;
});

const addressCopied = ref(null);

function copyToClipboard(address) {
  window.navigator.clipboard.writeText(address);
  addressCopied.value = address;
  setTimeout(() => {
    addressCopied.value = null;
  }, 3000);
}
</script>
