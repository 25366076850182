<template>
  <div class="inline-block min-w-full py-1 px-4">
    <table class="min-w-full text-xs font-semibold text-[#d3d1dd]">
      <tbody>
        <tr class="flex items-center space-x-4 border-b border-gray-500/20">
          <td class="whitespace-nowrap py-2 font-light">Tier</td>
          <td
            class="whitespace-nowrap py-2 text-right"
            :class="fetchBackgroundColor"
          >
            <div v-if="loading"></div>
            <div v-else-if="fetchTier == 'NONE'">
              <span class="default_mcf_text">
                {{ fetchTier }}
              </span>
            </div>
            <div v-else>
              {{ fetchTier }}
            </div>
          </td>
        </tr>
        <tr class="flex items-center space-x-4 border-b border-gray-500/20">
          <td class="whitespace-nowrap py-2 font-light">Rank</td>
          <td
            class="whitespace-nowrap py-2 text-right"
            :class="fetchBackgroundColor"
          >
            <span v-if="currentSnapshot?.current_snapshot?.rank">
              {{
                simpleNumberFormatter(currentSnapshot?.current_snapshot?.rank)
              }}
            </span>
            <span v-else-if="loading"></span>
            <span class="default_mcf_text" v-else> NONE </span>
          </td>
        </tr>
        <tr class="flex items-center space-x-4 border-b border-gray-500/20">
          <td class="whitespace-nowrap py-2 font-light">Points Earned</td>
          <td
            class="whitespace-nowrap py-2 text-right"
            :class="fetchBackgroundColor"
          >
            <div
              class="space-x-2"
              v-if="
                currentSnapshot?.most_recent_components &&
                currentSnapshot?.current_snapshot?.score
              "
            >
              <span>{{
                rounded(currentSnapshot?.current_snapshot?.score, 0)
              }}</span>
            </div>
            <span v-else-if="loading"></span>
            <div v-else class="default_mcf_text">-- / --</div>
          </td>
        </tr>
        <tr class="flex items-center space-x-4 border-b border-gray-500/20">
          <td class="whitespace-nowrap py-2 font-light">Participation Score</td>
          <td
            class="whitespace-nowrap py-2 text-right"
            :class="fetchBackgroundColor"
          >
            <span v-if="currentSnapshot?.current_snapshot?.activity_contr">
              {{
                chillFactorCalculation(
                  currentSnapshot?.current_snapshot?.activity_contr,
                  currentSnapshot?.current_snapshot?.score,
                )
              }}
            </span>
            <span v-else-if="loading"></span>
            <span class="default_mcf_text" v-else> --% </span>
          </td>
        </tr>
        <tr class="flex items-center space-x-4 border-b border-gray-500/20">
          <td class="whitespace-nowrap py-2 font-light">Consistency Score</td>
          <td
            class="whitespace-nowrap py-2 text-right"
            :class="fetchBackgroundColor"
          >
            <span v-if="currentSnapshot?.current_snapshot?.consistency_contr">
              {{
                chillFactorCalculation(
                  currentSnapshot?.current_snapshot?.consistency_contr,
                  currentSnapshot?.current_snapshot?.score,
                )
              }}
            </span>
            <span v-else-if="loading"></span>
            <span class="default_mcf_text" v-else> --% </span>
          </td>
        </tr>
        <tr class="flex items-center space-x-4">
          <td class="whitespace-nowrap py-2 font-light">Diversity Score</td>
          <td
            class="whitespace-nowrap py-2 text-right"
            :class="fetchBackgroundColor"
          >
            <span v-if="currentSnapshot?.current_snapshot?.diversity_contr">
              {{
                chillFactorCalculation(
                  currentSnapshot?.current_snapshot?.diversity_contr,
                  currentSnapshot?.current_snapshot?.score,
                )
              }}
            </span>
            <span v-else-if="loading"></span>
            <span class="default_mcf_text" v-else> --% </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import {
  setScoreBaseTextColor,
  setTier,
  formatPercent,
  readableDate,
  fetchTierCutOff,
  percentage,
  simpleNumberFormatter,
  rounded,
  chillFactorCalculation,
} from '../../composeables/filters';

const props = defineProps({
  myAddressStatus: {
    type: Object,
    default: {},
  },
  currentSnapshot: {
    type: Object,
    default: {},
  },
  loading: {
    type: Boolean,
  },
});

const fetchTier = computed(() => {
  if (!props.currentSnapshot?.current_snapshot) return 'NONE';
  return setTier(props.currentSnapshot?.current_snapshot?.score);
});

const fetchBackgroundColor = computed(() => {
  return setScoreBaseTextColor(props.currentSnapshot?.current_snapshot?.score);
});
</script>
