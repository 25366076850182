<template>
  <NoPageFound
    v-if="_.isEmpty(collection)"
    message="No NFT Collection Found!"
  />
  <div class="space-y-4" v-else>
    <div class="grid grid-cols-1 gap-4 pt-4 md:grid-cols-3">
      <NftHeader :collection="collection" class="col-span-1" />
      <BaseCard class="col-span-1 md:col-span-2">
        <template #header>Key Metrics</template>
        <div class="w-full overflow-x-auto rounded py-1.5 font-semibold">
          <Table :config="keyMetricsConfig" :data="keyMetricsData" />
        </div>
      </BaseCard>
    </div>

    <section>
      <div
        class="w-full gap-4 space-y-4 md:grid md:grid-cols-2 md:space-y-0 lg:grid-cols-3"
      >
        <UniqueHolders :collection="collection" />
        <HolderDistrbution :collection="collection" />
        <NftHeld :collection="collection" />
      </div>
    </section>

    <section>
      <div class="w-full gap-4 space-y-4 md:space-y-0 lg:grid lg:grid-cols-2">
        <Activity :collection="collection" />
        <FloorPrice :collection="collection" />
      </div>
    </section>

    <!-- Tables -->
    <div class="grid-cols-2 gap-4 space-y-4 md:grid md:space-y-0">
      <BaseCard class="col-span-1 overflow-x-auto overflow-y-auto md:h-[32rem]">
        <template #header>Top Holders</template>
        <div class="flex flex-shrink-0 flex-grow flex-col rounded">
          <div
            class="wrap flex-1 overflow-hidden overflow-x-auto overflow-y-auto rounded-lg text-sm shadow"
          >
            <div class="w-full">
              <TopHolders :collection="collection" class="h-72 md:h-36" />
            </div>
          </div>
        </div>
      </BaseCard>
      <BaseCard
        class="col-span-1 h-80 overflow-x-auto overflow-y-auto md:h-[32rem]"
      >
        <template #header>Latest Trades</template>
        <div class="flex flex-shrink-0 flex-grow flex-col rounded">
          <div
            class="wrap flex-1 overflow-hidden overflow-x-auto overflow-y-auto rounded-lg text-sm shadow"
          >
            <div class="w-full">
              <LatestTraders :collection="collection" class="h-72 md:h-36" />
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
    <Modal
      :collection="collection?.collection"
      :collection-name="collection?.collection_name"
      :tokenId="tokenId"
      :show-modal="showModal"
      v-if="showModal"
      @closeModal="closeModal()"
      class="bg-grid"
    />
  </div>
</template>

<script setup>
import { ref, inject, computed, onMounted, watch } from 'vue';
import NftHeader from '../../Shared/NftHeader.vue';
import Table from '../../Shared/Table.vue';
import UniqueHolders from './Charts/UniqueHolders.vue';
import HolderDistrbution from './Charts/HolderDistrbution.vue';
import NftHeld from './Charts/NftHeld.vue';
import Activity from './Charts/Activity.vue';
import FloorPrice from './Charts/FloorPrice.vue';
import Dropdown from '../../Shared/Dropdown.vue';
import NftBaseCard from '../../Shared/NftBaseCard.vue';
import Modal from '../../Shared/Modal.vue';
import _ from 'lodash';
import { NftApi } from '../Nfts/NftApi';
import IconLoading from '../../icons/IconLoading.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import NoPageFound from '../../Shared/NoPageFound.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
import LatestTraders from './LatestTraders.vue';
import BaseCard from '../../Shared/BaseCard.vue';
import TopHolders from './TopHolders.vue';

const props = defineProps({
  collection: Object,
});
const showModal = ref(false);
const tokens = inject('universeTokens');
const nftTokens = ref([]);
const dropdownName = ref('Rarity: High to Low');
const page = ref(0);
const canLoadMore = ref(true);
const loading = ref(true);
const loadMoreloading = ref(false);
const tokenId = ref();
const api = new NftApi();

onMounted(async () => {
  if (document.body.classList.contains('overflow-y-hidden')) {
    document.body.classList.remove('overflow-y-hidden');
  }
  if (!_.isEmpty(props.collection)) {
    nftTokens.value = await fetchCollectionTokens();
  }
  loading.value = false;
});

async function fetchCollectionTokens(page = 0) {
  let orderby = dropdown.value.action[dropdownName.value];
  let params = {
    page: page,
    order_by: orderby.column,
    order_asc_desc: orderby.sort,
  };
  return api.fetchCollectionTokens(props.collection?.collection, params);
}

async function loadMoreTokens() {
  let response = await fetchCollectionTokens(page.value + 1);
  if (response.length > 0) {
    nftTokens.value = [].concat(nftTokens.value, response);
    page.value += 1;
  } else {
    canLoadMore.value = false;
  }
  loadMoreloading.value = false;
}

watch(dropdownName, async () => {
  loading.value = true;
  page.value = 0;
  nftTokens.value = await fetchCollectionTokens(page.value);
  loading.value = false;
});

function openModal(e) {
  if (e.tokenId) {
    tokenId.value = e.tokenId;
    showModal.value = true;
    document.body.classList.add('overflow-y-hidden');
  }
}
function closeModal() {
  showModal.value = false;
  document.body.classList.remove('overflow-y-hidden');
}

const keyMetricsConfig = ref({
  cols: [
    {
      type: 'avax_price',
      name: 'Floor Price',
      id: 'floor_price',
      textLeft: 'left',
    },
    { type: 'number', name: 'Owners', id: 'owners' },
    {
      type: 'avax_price',
      name: 'Mint Price',
      id: 'mint_price',
      colClass: 'justify-end',
    },
    { type: 'number', name: 'Items', id: 'items' },
    {
      type: 'avax_price',
      name: 'Market Cap',
      id: 'market_cap',
      colClass: 'justify-end',
    },
    {
      type: 'dollar',
      name: '24hr Avg Sales (USD)',
      id: 'avg_sale_usd',
      percent: 'avg_sale_usd_percent',
      numbersLimit: 2,
    },
    {
      type: 'avax_price',
      name: '24hr Avg Sales (AVAX)',
      id: 'avg_sale_avax',
      percent: 'avg_sale_avax_percent',
      colClass: 'justify-end',
    },
    {
      type: 'avax_price',
      name: '24hr Volume',
      id: 'volume',
      percent: 'volume_percent',
      colClass: 'justify-end',
    },
  ],
  paddingLeftRight: 'px-3',
});

const keyMetricsData = computed(() => {
  let collection = props.collection;
  let avaxPrice = avax.value?.price || 1;
  let market =
    parseFloat(collection.floor_price) * collection.token_supply * avaxPrice;
  return [
    {
      floor_price: collection.floor_price,
      owners: collection.num_owners,
      mint_price: collection.mint_price,
      items: collection.token_supply,
      market_cap: market,
      avg_sale_usd: collection.avrg_price * avaxPrice,
      avg_sale_usd_percent:
        (collection.avrg_price * avaxPrice) /
          (collection.prev_daily_avrg_price * avaxPrice) -
        1,
      avg_sale_avax: collection.avrg_price,
      avg_sale_avax_percent:
        collection.avrg_price / collection.prev_daily_avrg_price - 1,
      volume: collection.vol,
      volume_percent: 0,
    },
  ];
});

const avax = computed(() => {
  let allTokens = tokens.value || [];
  return allTokens.find((x) => x.ticker == 'AVAX');
});

const dropdown = ref({
  items: [
    {
      id: 4,
      title: 'Token ID: High to Low',
    },
    {
      id: 5,
      title: 'Token ID: Low to High',
    },
    {
      id: 6,
      title: 'Rarity: High to Low',
    },
    {
      id: 7,
      title: 'Rarity: Low to High',
    },
  ],
  action: {
    'Token ID: High to Low': {
      column: 'token_id',
      sort: 'desc',
    },
    'Token ID: Low to High': {
      column: 'token_id',
      sort: 'asc',
    },
    'Rarity: High to Low': {
      column: 'rank',
      sort: 'asc',
    },
    'Rarity: Low to High': {
      column: 'rank',
      sort: 'desc',
    },
  },
});
</script>
