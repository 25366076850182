<template>
  <BaseCard>
    <template #header>Notable Listings</template>
    <template #controls>
      <BaseRadio
        v-model="selectedFilter"
        multiple
        button-width-class="w-32"
        :options="filterTypes"
        placeholder="Select Type"
      />
    </template>
    <span
      v-if="!loading && !currentRow?.length > 0"
      class="flex h-[21rem] w-full justify-around"
    >
      <NoDataFound />
    </span>
    <div
      v-else
      class="grid w-full grid-cols-1 gap-x-3 gap-y-3 py-3 px-4 xs:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
    >
      <span v-if="loading" v-for="i in paginationLength()" :key="i">
        <TopNftTemplateCard />
      </span>
      <NftBaseCard
        :nft="nft"
        v-for="nft in currentRow"
        :key="getRandomId()"
        v-else-if="currentRow?.length > 0"
        @nft-clicked="(x) => openModal(x)"
      />
      <Modal
        :collection="collectionId"
        :collection-name="collectionName"
        :tokenId="tokenId"
        :show-modal="showModal"
        v-if="showModal"
        @closeModal="closeModal()"
      />
    </div>
    <div class="flex items-center space-x-4 px-8 pb-4 pt-2">
      <div
        class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-solid font-medium"
        :class="prevPage ? 'cursor-pointer  text-white' : 'text-gray-400'"
        @click="prevPage ? (page -= 1) : undefined"
      >
        <ChevronLeftIcon class="h-5 w-5" />
      </div>

      <div class="text-sm text-gray-400" v-if="!loading">
        {{ pages > 0 ? page + 1 : 0 }} of {{ pages }}
      </div>
      <div v-else class="text-sm text-gray-400">
        {{ loading ? 'Loading' : '' }}
      </div>
      <div
        class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-solid"
        :class="nextPage ? 'cursor-pointer  text-white' : 'text-gray-400'"
        @click="nextPage ? (page += 1) : undefined"
      >
        <ChevronRightIcon class="h-5 w-5" />
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted, reactive, watch } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';
import NftBaseCard from '../../Shared/NftBaseCard.vue';
import TopNftTemplateCard from '../../load_templates/TopNftsTemplateCard.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import Modal from '../../Shared/Modal.vue';
import { NftApi } from '../Nfts/NftApi';
import { getRandomId } from '../../composeables/filters.js';
import BaseCard from '../../Shared/BaseCard.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
import BaseRadio from '../../Shared/BaseRadio.vue';
const api = new NftApi();

const page = ref(0);
const pagination = ref(2);
const showModal = ref(false);
const tokenId = ref();
const collectionId = ref();
const collectionName = ref();
const topNfts = ref([]);
const loading = ref(true);
const selectedFilter = ref('24H');
const filterTypes = reactive(['24H', '7D', '30D']);

onMounted(() => {
  Promise.all([fetchTopNfts()]);
});

watch(selectedFilter, async () => {
  await fetchTopNfts();
});

async function fetchTopNfts() {
  let selectedTimeFrame =
    selectedFilter.value == '24H'
      ? '1day'
      : selectedFilter.value == '7D'
      ? '7day'
      : '30day';
  topNfts.value = await api.fetchTopNfts({
    order_by: selectedTimeFrame,
  });
  loading.value = false;
}

function paginationLength() {
  if (width.value < 475) {
    pagination.value = 1;
  } else if (width.value >= 475 && width.value < 769) {
    pagination.value = 3;
  } else if (width.value > 768 && width.value < 1025) {
    pagination.value = 4;
  } else if (width.value > 1024 && width.value < 1451) {
    pagination.value = 5;
  } else if (width.value > 1450) {
    pagination.value = 6;
  }
  return pagination.value;
}

const currentRow = computed(() => {
  let result = [];
  let pagination = paginationLength();

  if (topNfts.value?.length > 0) {
    result = topNfts.value?.slice(
      page.value * pagination,
      pagination * page.value + pagination,
    );
  }
  return result;
});

const nextPage = computed(() => {
  return (
    topNfts.value?.length > page.value * pagination.value + pagination.value
  );
});

const prevPage = computed(() => {
  return page.value !== 0;
});

const pages = computed(() => {
  return Math.ceil(topNfts.value?.length / pagination.value);
});

onMounted(() => {
  window.addEventListener('resize', getDimensions);
  getDimensions();
});

onUnmounted(() => {
  window.removeEventListener('resize', getDimensions);
});

const width = ref();

function getDimensions() {
  width.value = document.getElementById('topNFTsContainer').clientWidth;
}
function openModal(e) {
  collectionId.value = e.collection;
  tokenId.value = e.tokenId;
  collectionName.value = e.name;
  showModal.value = true;
  document.body.classList.add('overflow-y-hidden');
}
function closeModal() {
  showModal.value = false;
  document.body.classList.remove('overflow-y-hidden');
}
</script>
