<template>
  <table
    class="w-full text-left text-sm text-gray-400"
    style="font-family: 'Source Code Pro', monospace"
  >
    <thead
      class="sticky top-0 border-b border-[#f3f2f614] bg-[#f3f2f60a] text-xs text-[#EEEEF0] backdrop-blur-md"
      :class="`z-15  text-xs ${config.uppercase ? 'uppercase' : ''}`"
      style="font-family: 'Source Sans 3', monospace"
    >
      <tr class="z-10 border-b border-[#f3f2f614]">
        <th
          scope="col"
          v-for="th in config.cols"
          :key="`th_${th.id}`"
          :class="`cursor-pointer py-2 text-xs font-semibold ${
            th.textLeft ? 'text-left' : 'text-right'
          } ${config.paddingLeftRight ? config.paddingLeftRight : ''}`"
          @click="sortBy(th.id)"
        >
          <span
            :class="`items-center ${
              sortKey && !th.textLeft
                ? 'flex justify-end'
                : th.textLeft
                ? 'flex'
                : ''
            } `"
          >
            <span v-if="!th.textLeft && th.id == sortKey">
              <PlayIcon
                class="w-2"
                :class="`${order == 'desc' ? 'rotate-90' : 'rotate-[270deg]'}`"
                v-if="['desc', 'asc'].includes(order)"
              />
            </span>
            {{ th.name }}
            <span v-if="th.textLeft && th.id == sortKey">
              <PlayIcon
                class="w-2"
                :class="`${order == 'desc' ? 'rotate-90' : 'rotate-[270deg]'}`"
                v-if="['desc', 'asc'].includes(order)"
              />
            </span>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in filteredData" :key="`tr_${index}}`">
        <td
          v-for="col in config.cols"
          :key="`td_${index}_${row[col.id]}}_${getRandomId()}`"
          scope="row"
          class="h-[2.8rem] whitespace-nowrap border-b border-[#f3f2f614] text-right text-xs font-medium text-gray-400"
          :class="`py-2 ${
            config.paddingLeftRight ? config.paddingLeftRight : ''
          }`"
        >
          <div v-if="col.type === 'text'" class="text-left" :class="col.style">
            {{
              col.noSeparator
                ? row[col.id]
                : titleize(row[col.id], col.separator || '-')
            }}
          </div>

          <div v-else-if="col.type === 'coin'">
            <div class="flex items-center space-x-2">
              <img
                :src="row[col.id].logo"
                class="h-5"
                v-if="row[col.id]?.logo"
              />
              <span class="text-neutral-100">
                {{ row[col.id]?.name }}
              </span>
            </div>
          </div>

          <div v-else-if="col.type === 'number'" class="tabular-nums">
            <span v-if="col.formatNumber">
              {{ formatNumber(row[col.id], col.numbersLimit || 6) }}
            </span>
            <span v-else>
              {{ rounded(row[col.id], 0) || '-' }}
            </span>
          </div>

          <div v-else-if="col.type === 'float_number'" class="tabular-nums">
            <span v-if="col.formatNumber">
              {{ formatNumber(row[col.id], col.numbersLimit || 6) }}
            </span>
            <span v-else>
              {{ rounded(row[col.id], 2) || '-' }}
            </span>
          </div>

          <div v-else-if="col.type === 'decimal'" class="tabular-nums">
            <span
              :class="`${
                col.colors && row[col.id] >= 0
                  ? 'text-green-400'
                  : col.colors && row[col.id] < 0
                  ? 'text-red-400'
                  : ''
              }`"
            >
              {{ rounded(row[col.id]) || '-' }}
            </span>
          </div>

          <div v-else-if="col.type === 'percent'" class="tabular-nums">
            <span v-if="row[col.id]">
              <Percent
                :value="row[col.id]"
                :no-multiply="col.noMultiply"
                :showZero="col.showZero"
                :key="row[col.id]"
                :show-color-symbol="col.showColorSymbol"
              />
            </span>
            <span v-else>-</span>
          </div>

          <div v-else-if="col.type === 'dollar'" class="tabular-nums">
            <span v-if="isNaN(row[col.id]) || parseFloat(row[col.id]) == 0.0"
              >-</span
            >
            <span v-else>
              ${{ formatNumber(row[col.id], col.numbersLimit || 6) }}
            </span>
            <Percent
              :value="row[col.percent]"
              v-if="col.percent"
              class="pl-2"
              :key="row[col.percent]"
            />
          </div>

          <div v-else-if="col.type === 'dollar_number'" class="tabular-nums">
            <span v-if="parseInt(row[col.id]).toLocaleString() != 'NaN'">
              <span>{{ parseInt(row[col.id]).toLocaleString() }}</span>
            </span>
            <span v-else-if="parseFloat(row[col.id].substring(1))">
              <span>{{ row[col.id].substring(0, 1) }}</span>
              <span
                >{{ parseInt(row[col.id].substring(1)).toLocaleString() }}
              </span>
            </span>
          </div>

          <div
            v-else-if="col.type === 'avax_price'"
            class="flex items-center tabular-nums"
            :class="col.colClass"
          >
            <IconAvalanche class="h-4 w-4 text-gray-400" v-if="!col.hideIcon" />
            <span :class="`${col.hideIcon ? '' : 'pl-2'}`">
              {{ formatNumber(row[col.id], 2) }}
            </span>
            <span class="pl-1" v-if="col.hideIcon"> AVAX </span>
            <Percent
              :value="row[col.percent]"
              v-if="col.percent"
              class="pl-2"
              :key="row[col.percent]"
            />
          </div>

          <div
            v-else-if="col.type === 'game'"
            class="text-left text-neutral-100"
          >
            <Link
              :href="`/projects/${row[col.id]}`"
              class="flex items-center space-x-1.5"
            >
              <span class="h-5 w-5">
                <img :src="getGameIcon(row[col.id])" class="rounded-3xl" />
              </span>
              <span>
                {{ titleize(row[col.id], ' ') }}
              </span>
            </Link>
          </div>

          <div
            v-else-if="col.type === 'game_api'"
            class="text-left text-neutral-100"
          >
            <Link
              :href="`/projects/${row[col.id]}`"
              class="flex items-center space-x-1.5"
            >
              <span class="h-5 w-5">
                <img
                  :src="getGameIcon(convertUnderScoreToDash(row[col.id]))"
                  class="rounded-3xl"
                />
              </span>
              <span>
                {{ titleize(convertUnderScoreToDash(row[col.id]), ' ') }}
              </span>
            </Link>
          </div>

          <div
            v-else-if="col.type === 'nft'"
            class="text-left text-neutral-100"
            :class="col.style"
          >
            <Link
              :href="`/nfts/${row.link ? row.link : row[col.id]}`"
              class="flex items-center"
            >
              <span class="mr-2 h-5 w-5" v-if="isValidUrl(row?.image)">
                <img :src="row.image" class="rounded-3xl" />
              </span>
              <span
                class="mr-2 h-5 w-5 rounded-3xl bg-gray-700/50"
                v-else
              ></span>

              {{ titleize(row[col.id], ' ') }}
            </Link>
          </div>

          <div
            v-else-if="col.type === 'market_place'"
            class="flex cursor-pointer items-center text-left"
          >
            <img
              class="mr-2 h-5 w-5 rounded-3xl object-fill"
              :src="getMarketInfo(row[col.id])?.icon"
            />
            <a target="_blank" :href="getMarketInfo(row[col.id])?.url">{{
              titleize(row[col.id])
            }}</a>
          </div>

          <div
            v-else-if="col.type === 'subnets'"
            class="flex cursor-pointer items-center text-left text-gray-200"
          >
            <img
              class="mr-2 h-5 w-5 rounded-3xl object-fill"
              :src="row.image"
            />
            <a target="_blank" :href="row.url">{{ titleize(row.name, ' ') }}</a>
          </div>

          <div v-else-if="col.type === 'link'">
            <a
              target="_blank"
              :href="row[col.id]?.link"
              class="font-semibold text-red-500"
              >{{ row[col.id]?.name || '-' }}</a
            >
          </div>

          <div
            v-else-if="col.type === 'linkIcon'"
            :class="col.style"
            class="flex items-end justify-center"
          >
            <a target="_blank" :href="row[col.id]?.link" class="font-semibold">
              <ArrowUpRightIcon class="h-4 w-4" />
            </a>
          </div>

          <div v-else-if="col.type === 'key_metrics'">
            <span v-if="row.value || row.value == 0">
              <span v-if="row.type === 'dollar'">
                ${{ rounded(row.value, 0) }}
              </span>
              <Percent
                :value="row.value"
                v-else
                class="pl-2"
                :key="row[col.percent]"
              />
            </span>
            <span class="text-gray-500" v-else> n/a </span>
          </div>

          <div
            v-else-if="col.type === 'defi'"
            class="text-left text-neutral-100"
          >
            <Link
              :href="`/defi/${row.link ? row.link : row[col.id]}`"
              class="flex items-center"
              v-if="col.openLink"
            >
              <span class="mr-2 h-5 w-5" v-if="isValidUrl(row?.image)">
                <img :src="row.image" class="rounded-3xl" />
              </span>
              <span class="mr-2 h-5 w-5 rounded-3xl bg-gray-800" v-else></span>
              {{ row[col.id] }}
            </Link>
            <span class="flex items-center" v-else>
              <span class="mr-2 h-5 w-5" v-if="isValidUrl(row?.image)">
                <img :src="row.image" class="rounded-3xl" />
              </span>
              <span class="mr-2 h-5 w-5 rounded-3xl bg-gray-800" v-else></span>
              {{ row[col.id] }}
            </span>
          </div>

          <div
            v-else-if="col.type === 'tokens'"
            class="text-left text-neutral-100"
          >
            <Link
              :href="`/tokens/${row.link ? row.link : row[col.id]}`"
              class="flex items-center text-sm font-semibold text-[#CFCDDA]"
              v-if="col.openLink"
            >
              <span class="mr-2 h-5 w-5" v-if="isValidUrl(row?.image)">
                <img :src="row.image" class="rounded-3xl" />
              </span>
              <span class="mr-2 h-5 w-5 rounded-3xl bg-gray-800" v-else></span>
              {{ row[col.id] }}
            </Link>
            <span class="flex items-center" v-else>
              <span class="mr-2 h-5 w-5" v-if="isValidUrl(row?.image)">
                <img :src="row.image" class="rounded-3xl" />
              </span>
              <span class="mr-2 h-5 w-5 rounded-3xl bg-gray-800" v-else></span>
              {{ row[col.id] }}
            </span>
          </div>

          <div
            v-else-if="col.type === 'number_percent'"
            class="flex w-full justify-end"
          >
            <div v-if="row.tradingVolumePrice">
              ${{ rounded(row.tradingVolumePrice, 0) }}
            </div>
            <div v-if="row.tradingVolumePercent" class="w-1/3">
              <Percent :value="row.tradingVolumePercent" class="pl-2" />
            </div>
            <div
              class="w-1/3 text-gray-500"
              v-if="!row.tradingVolumePrice && !row.tradingVolumePercent"
            >
              n/a
            </div>
          </div>

          <div v-else-if="col.type === 'addresses'" :class="col.style">
            {{
              row[col.id]?.substring(0, 7) + '......' + row[col.id]?.slice(-7)
            }}
          </div>

          <div v-else-if="col.type === 'date'" :class="col.style">
            {{ row[col.id] || '-' }}
          </div>

          <div v-else>
            {{ row[col.id] || '-' }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup>
import Tooltip from '../Shared/Tooltip.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import { Link } from '@inertiajs/inertia-vue3';
import {
  percentage,
  formatNumber,
  decimals,
  formatPrice,
  rounded,
  titleize,
  isValidUrl,
  convertUnderScoreToDash,
} from '../composeables/filters.js';
import { ref, markRaw, computed } from 'vue';
import { ArrowUpRightIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import IconAvalanche from '../icons/IconAvalanche.vue';
import Percent from './Percent.vue';
import { getRandomId } from '../composeables/filters';
import _ from 'lodash';
import { PlayIcon } from '@heroicons/vue/20/solid';

const store = useStore();

const props = defineProps({
  data: {
    type: Array,
  },
  config: {
    type: Object,
  },
});

function getGameIcon(name) {
  if (name?.length <= 0) {
    return name;
  } else {
    name = name?.replace(/\s/g, '');
    name = name == 'dragoncrypto' ? 'dragoncryptogame' : name;
    let extension = [
      'castlecrush',
      'dragoncryptogame',
      'paradisetycoon',
    ].includes(name)
      ? '.png'
      : '.svg';
    return store.getters.projectImagesBaseLink + name + extension;
  }
}
function getMarketInfo(name) {
  name = name.replace(/\s/g, '');
  let imageUrl = store.getters.marketplaceImageBaseLink + name + '.png';
  return (
    {
      joepegs: {
        icon: imageUrl,
        url: 'https://joepegs.com/collections/',
      },
      campfire: {
        icon: imageUrl,
        url: 'https://campfire.exchange',
      },
      kalao: {
        icon: imageUrl,
        url: 'https://marketplace.kalao.io',
      },
      nftrade: {
        icon: imageUrl,
        url: 'https://nftrade.com',
      },
      opensea: {
        icon: imageUrl,
        url: 'https://opensea.io/',
      },
      chikn: {
        icon: imageUrl,
        url: 'https://chikn.farm/',
      },
      raffllrr: {
        icon: imageUrl,
        url: 'https://www.raffllrr.xyz/',
      },
      salvor: {
        icon: imageUrl,
        url: 'https://salvor.io/',
      },
      hyperspace: {
        icon: imageUrl,
        url: 'https://avax.hyperspace.xyz/',
      },
      zeroone: {
        icon: imageUrl,
        url: 'https://zeroone.art/',
      },
    }[name] || name
  );
}

const order = ref('');
const sortKey = ref('');
function sortBy(sortId) {
  if (sortKey.value != sortId) {
    order.value = '';
  }
  sortKey.value = sortId;
  if (order.value == '') {
    order.value = 'desc';
  } else if (order.value == 'desc') {
    order.value = 'asc';
  } else {
    order.value = '';
  }
}

const filteredData = computed(() => {
  let nameBaseColumns = [
    'game',
    'market_place',
    'metrics',
    'collection',
    'defi',
    'tokens',
  ];
  let trim = nameBaseColumns.includes(sortKey.value);
  if (order.value != '') {
    if (trim) {
      let sortData = _.orderBy(
        props.data,
        [(data) => data[sortKey.value]?.trim()],
        [order.value],
      );
      return sortData.sort(function (data) {
        return data[sortKey.value] === null ? 1 : -1;
      });
    } else {
      let sortData = _.orderBy(
        props.data,
        [(data) => parseData(data[sortKey.value], sortKey.value)],
        [order.value],
      );
      return sortData.sort(function (data) {
        return data[sortKey.value] === null ? 1 : -1;
      });
    }
  } else {
    return props.data;
  }
});

function parseData(data, sortKey) {
  return sortKey == 'floor_price' ? parseFloat(data) : data;
}
</script>
