<template>
  <div
    class="flex justify-center items-center px-4"
    :style="`height: ${innerHeight}px`"
  >
    <div
      class="
        h-32
        w-full
        sm:w-1/3
        bg-avalanche-gray
        flex flex-col
        p-4
        rounded-lg
      "
    >
      <span
        class="text-2xl font-bold flex space-x-1 items-center text-gray-300"
      >
        <InformationCircleIcon class="w-6 h-6" /> <span>Opps...</span>
      </span>
      <span class="text-gray-400"> {{ message }}</span>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
const props = defineProps({
  message: String,
});
const innerHeight = ref(window.innerHeight);
onMounted(async () => {
  innerHeight.value -= 270;
});
</script>
