<template>
  <div
    class="w-full space-y-4 pb-4 md:items-center lg:flex lg:space-x-4 lg:space-y-0"
  >
    <div class="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-3">
      <BaseCard
        class="col-span-1 w-full"
        tooltip-text="This is a breakdown of your total Chill Factor score by individual metrics. See how much a specific metric contributes to your overall Chill Factor score!"
      >
        <template #header
          ><span class="pl-3">Chill Factor by Checkpoints</span></template
        >
        <div
          class="mt-8 flex h-full w-full items-center justify-center"
          v-show="loading"
        >
          <IconLoading class="h-6 w-6" />
        </div>
        <NoWalletConnected
          v-show="!checkpointSnapshot?.cf_by_checkpoints && !loading"
          class="mt-8 h-full w-full"
        />
        <TreeMapChart
          :checkpoint="checkpointSnapshot"
          v-show="checkpointSnapshot?.cf_by_checkpoints"
        />
      </BaseCard>
      <BaseCard class="col-span-2">
        <div
          class="w-full items-center justify-between py-3 px-1 md:flex md:pb-6"
        >
          <div class="flex items-center space-x-1">
            <p>Checkpoints</p>
            <Tooltip
              text="Checkpoints are a set of goals for the metrics we use to calculate your Chill Factor score. Reach a goal to receive a bonus based on the size of the Checkpoint interval. Bonuses are rewarded at consistent intervals, and all Checkpoints you've already reached have been added to your score. The displayed data show raw metric values."
              class="ml-0.5"
            >
              <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
            </Tooltip>
          </div>
          <span class="text-sm uppercase tracking-wider" v-if="formateDate">
            Updated
            {{ formateDate }}
          </span>
        </div>
        <div class="w-full space-x-4 space-y-3 md:flex md:flex-1">
          <div class="w-full space-y-2 md:w-1/2">
            <div class="h-full">
              <div
                v-for="(checkpoint, index) in checkpointsDataFiltered"
                :key="checkpoint.metric"
                class="flex items-center justify-between py-1 text-sm"
                :class="
                  index !== checkpointsDataFiltered.length - 1 &&
                  selectedCheckpoint != checkpoint.metric
                    ? 'border-b border-gray-400/25'
                    : ''
                "
              >
                <div
                  v-if="selectedCheckpoint != checkpoint.metric"
                  class="flex w-full cursor-pointer items-center justify-between py-1 px-4 text-sm"
                  @click="selectedCheckpoint = checkpoint.metric"
                >
                  <p class="text-gray-100">
                    {{ checkpoint.title }}
                  </p>
                  <div class="text-xs">
                    <p class="text-right">Progress</p>
                    <div class="flex justify-end" v-if="checkpoint.total > 0">
                      {{
                        checkpoint.metric == 'gas_paid'
                          ? simpleNumberFormatter(
                              checkpoint?.progress?.toFixed(2),
                            ) +
                            ' / ' +
                            simpleNumberFormatter(checkpoint?.total.toFixed(2))
                          : simpleNumberFormatter(checkpoint?.progress) +
                            ' / ' +
                            simpleNumberFormatter(checkpoint?.total)
                      }}
                    </div>
                    <div v-else>
                      <p class="text-right">--/--</p>
                    </div>
                  </div>
                </div>

                <div
                  v-else
                  class="mb-2 h-2/5 w-full rounded-lg bg-tertiary/50 py-4 text-neutral-200 transition delay-150 duration-300 ease-out"
                >
                  <div class="h-full w-full space-y-4 px-4">
                    <div>
                      <div class="progress-container">
                        <div
                          class="progress-bar rounded"
                          :style="{ width: progressBarWidth }"
                        ></div>
                      </div>
                    </div>
                    <div class="flex items-start justify-between">
                      <p class="font-semibold text-neutral-100">
                        {{ checkpoint.title }}
                      </p>

                      <div class="flex items-center space-x-2">
                        <div
                          v-if="checkpoint.change"
                          class="flex items-center space-x-1 rounded px-2 py-1 text-xs leading-4"
                          :class="
                            checkpoint.change < 0
                              ? 'bg-[#4b0f0f73] text-red-400'
                              : 'bg-teal-900 text-teal-300'
                          "
                        >
                          <ArrowUpIcon
                            v-if="checkpoint.change > 0"
                            class="h-3 w-3"
                          />
                          <ArrowDownIcon
                            v-if="checkpoint.change < 0"
                            class="h-3 w-3"
                          />
                          <span>{{
                            checkpoint.metric == 'gas_paid'
                              ? simpleNumberFormatter(
                                  checkpoint.change.toFixed(2),
                                )
                              : simpleNumberFormatter(checkpoint.change)
                          }}</span>
                        </div>
                        <div
                          class="flex justify-end text-sm text-white"
                          v-if="checkpoint.total > 0"
                        >
                          {{
                            checkpoint.metric == 'gas_paid'
                              ? simpleNumberFormatter(
                                  checkpoint.progress.toFixed(2),
                                ) +
                                ' / ' +
                                simpleNumberFormatter(
                                  checkpoint.total.toFixed(2),
                                )
                              : simpleNumberFormatter(checkpoint.progress) +
                                ' / ' +
                                simpleNumberFormatter(checkpoint.total)
                          }}
                        </div>
                        <div v-else>
                          <p class="text-right">--/--</p>
                        </div>
                      </div>
                    </div>

                    <p class="text-xs text-white">
                      {{ checkpoint.description }}
                    </p>
                    <p class="flex items-center text-xs text-gray-400">
                      <BoltIcon class="h-3.5 w-3.5" />
                      <span class="px-1 leading-4">
                        {{
                          ['txns_out', 'unique_days', 'gas_paid'].includes(
                            checkpoint.metric,
                          )
                            ? 'Participation Score'
                            : 'months_in_streak' == checkpoint.metric
                            ? 'Consistency Score'
                            : 'Diversity Score'
                        }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="h-full w-full md:w-1/2">
            <div
              class="flex flex-shrink flex-grow flex-col overflow-x-auto rounded-lg sm:h-80 md:h-full"
            >
              <div
                class="flex h-full w-full items-center justify-center"
                v-if="loading"
              >
                <IconLoading class="h-6 w-6" />
              </div>
              <NoWalletConnected
                class="m-auto"
                v-else-if="!checkPointHistorical?.[0]?.data?.length"
              />
              <NoChartData
                class="m-auto"
                v-else-if="!checkPointHistorical?.[0]?.exists"
              />
              <BarChart
                :data-series="checkPointHistorical"
                :config="stackChartConfig"
                :tooltip-titlize="false"
                v-else
              />
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import {
  BoltIcon,
  InformationCircleIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from '@heroicons/vue/24/outline';

import Tooltip from '../../Shared/Tooltip.vue';
import moment from 'moment';

import TreeMapChart from '../../Widgets/TreeMapChart.vue';
import NoWalletConnected from '../../load_templates/NoWalletConnected.vue';
import BaseCard from '../../Shared/BaseCard.vue';

import IconLoading from '../../icons/IconLoading.vue';
import BarChart from '../../Widgets/BarChart.vue';
import { simpleNumberFormatter } from '../../composeables/filters';
import NoChartData from '@/load_templates/NoChartData.vue';
const props = defineProps({
  checkpointSnapshot: {
    type: Object,
    default: {},
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

const progressBarWidth = ref('0%'); // Initial progress bar width

const checkpointsMetricsData = ref([
  {
    metric: 'txns_out',
    alternate: 'transactions',
    title: 'Total Transactions',
    progress: 0,
    total: 0,
    change: null,
    description: '',
  },
  {
    metric: 'unique_contracts',
    title: 'Unique Contracts',
    progress: 0,
    total: 0,
    change: null,
    description: '',
  },
  {
    metric: 'months_in_streak',
    title: 'Streak Months',
    progress: 0,
    total: 0,
    change: null,
    description: '',
  },
  {
    metric: 'unique_tokens',
    title: 'Unique Tokens Owned',
    progress: 0,
    total: 0,
    change: null,
    description: '',
  },
  {
    metric: 'unique_nfts',
    title: 'Unique NFTs Collections',
    progress: 0,
    total: 0,
    change: null,
    description: '',
  },
  {
    metric: 'gas_paid',
    title: 'Total Gas Spent',
    progress: 0,
    total: 0,
    change: null,
    description: '',
  },
  {
    metric: 'unique_days',
    title: 'Active Days',
    progress: 0,
    total: 0,
    change: null,
    description: '',
  },
]);

const selectedCheckpoint = ref('txns_out');

watch(selectedCheckpoint, () => {
  calculateBar();
});

var BarSeriesName = '';
function calculateBar() {
  if (props.checkpointSnapshot?.metric_checkpoints) {
    const selectedCheckpointPayload = checkpointsMetricsData.value.find(
      (checkpoint) => checkpoint.metric === selectedCheckpoint.value,
    );
    const mostRecentVal =
      props.checkpointSnapshot?.most_recent_metrics?.[
        selectedCheckpointPayload.metric
      ] || 0;
    const metricCheckpointVal =
      props.checkpointSnapshot?.metric_checkpoints?.[
        selectedCheckpointPayload.metric
      ] ||
      props.checkpointSnapshot?.metric_checkpoints?.[
        selectedCheckpointPayload.alternate
      ] ||
      0;
    BarSeriesName = checkpointsMetricsData.value.find(
      (item) => item.metric == selectedCheckpointPayload.metric,
    )?.title;
    progressBarWidth.value = `${(mostRecentVal / metricCheckpointVal) * 100}%`;
  } else {
    return '0%';
  }
}
const descriptionKeys = ref({
  txns_out: 'transactions',
  unique_contracts: 'contracts',
  months_in_streak: 'months_in_streak',
  unique_tokens: 'tokens',
  unique_nfts: 'nfts',
  gas_paid: 'gas',
  unique_days: 'unique_days',
});

const checkpointsDataFiltered = computed(() => {
  return checkpointsMetricsData.value.map((checkpoint) => {
    if (checkpoint.metric == 'gas_paid') {
      checkpoint.progress =
        props.checkpointSnapshot?.most_recent_metrics?.gas_paid || 0;
      checkpoint.total =
        props.checkpointSnapshot?.metric_checkpoints?.[checkpoint.metric];
    } else {
      checkpoint.progress =
        props.checkpointSnapshot?.most_recent_metrics?.[checkpoint.metric] || 0;
      checkpoint.total =
        props.checkpointSnapshot?.metric_checkpoints?.[checkpoint.metric] ||
        props.checkpointSnapshot?.metric_checkpoints?.[checkpoint.alternate] ||
        0;
    }
    checkpoint.description =
      props.checkpointSnapshot?.checkpoint_descriptions?.[
        descriptionKeys.value[checkpoint.metric]
      ] || '';
    if (props.checkpointSnapshot?.recent_two_metrics_historical?.length > 0) {
      checkpoint.change =
        props.checkpointSnapshot?.recent_two_metrics_historical[0][
          checkpoint.metric
        ] -
        props.checkpointSnapshot?.recent_two_metrics_historical[1][
          checkpoint.metric
        ];
    }
    return checkpoint;
  });
});

// Chart Configuration

const stackLoading = ref(false);
const stackChartConfig = ref({
  type: 'area',
  height: '500',
  useUTC: true,
  onlyMonthYear: true,
  colors: ['#3CABFF'],
  plotOptions: {
    area: {
      lineWidth: 1,
      lineColor: 'var(--Blue-Blue-800, #3CABFF)',
      marker: {
        enabled: false,
      },
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, 'rgba(0, 148, 255, 0.25)'],
          [1, 'rgba(64, 116, 153, 0)'],
        ],
      },
    },
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
      formatter: function () {
        return moment(this.value).utc().format('MMM YY');
      },
    },
    type: 'datetime',
    lineWidth: 0,
    tickLength: 0,
  },
  yAxis: {
    gridLineColor: '#262626',
    title: {
      text: '',
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 350,
        },
        chartOptions: {
          plotOptions: {
            column: {
              pointPadding: 0.1,
              borderWidth: 0,
            },
          },
        },
      },
    ],
  },
});

const checkpointsConfig = ref({});
const checkPointHistorical = computed(() => {
  let dataArray = [];
  if (props.checkpointSnapshot) {
    let date = new Date(
      props.checkpointSnapshot.metrics_historical?.[0].last_updated,
    );
    checkpointsConfig.value.last_updated_at = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    props.checkpointSnapshot.metrics_historical?.map((item) => {
      dataArray.push([
        new Date(item?.cur_date).getTime(),
        item?.[selectedCheckpoint.value] || 0,
      ]);
    });
  }
  return [
    {
      name: BarSeriesName,
      data: dataArray,
      exists: dataArray.some((x) => x[1] > 0),
    },
  ];
});

const formateDate = computed(() => {
  const dateString =
    props.checkpointSnapshot?.most_recent_metrics?.last_updated;
  if (!dateString) return null;
  const momentObj = moment(dateString, 'YYYY-MM-DD HH:mm:ss');
  const formattedDate = momentObj.format('MMMM DD, YYYY');
  calculateBar();
  return formattedDate;
});
</script>

<style scope>
.progress-container {
  width: 100%;
  height: 10px;
  background-color: #f3f2f614;
}

.progress-bar {
  height: 100%;
  background-color: #1cfce2;
}
</style>
